import { Dictionary } from '../Common/Types'
import { NumberMap } from '../Common/Interfaces'
import { DiceUtility } from '../Common/Utility'

export class Dice {
  diceCount: number
  diceValue: number
  originalDiceString: string
  fixedValue: number = 0

  constructor(diceBlob: Dictionary) {
    this.diceCount = parseInt(diceBlob.diceCount)
    this.diceValue = parseInt(diceBlob.diceValue)
    this.originalDiceString = diceBlob.diceString
    this.fixedValue = parseInt(diceBlob.fixedValue)
    if (!this.fixedValue || this.fixedValue === null) {
      this.fixedValue = 0
    }
  }

  diceString() {
    if (this.diceCount === 0 || this.diceValue === 0) {
      return this.fixedValue.toString()
    }

    const diceString = this.diceCount.toString() + 'd' + this.diceValue.toString()
    if (this.fixedValue) {
      if (this.fixedValue > 0) {
        return `${diceString} + ${this.fixedValue}`
      } else {
        return `${diceString}${this.fixedValue}`
      }
    }

    return diceString
  }

  copy(): Dice {
    return new Dice({
      diceCount: this.diceCount,
      diceValue: this.diceValue,
      fixedValue: this.fixedValue,
      diceString: this.diceString()
    })
  }

  max() {
    return this.diceCount * this.diceValue + this.fixedValue
  }

  min() {
    return this.diceCount
  }

  median() {
    return Math.floor(this.max() / 2)
  }

  avg() {
    return (this.diceCount * (this.diceValue + 1)) / 2 + this.fixedValue
  }

  isGreaterThan(otherDice: Dice) {
    return this.max() > otherDice.max()
  }

  static flatAmountDie(damage: number) {
    return new Dice({
      diceCount: 0,
      diceValue: 0,
      fixedValue: damage,
      diceString: damage.toString()
    })
  }

  static Create(count: number, value: number, fixedValue: number = 0) {
    return new Dice({
      diceCount: count,
      diceValue: value,
      fixedValue: fixedValue
    })
  }
}

export class DiceCollection {
  dice: { [key: string]: number }
  modifier: number

  constructor() {
    this.dice = {}
    this.modifier = 0
  }

  copy(): DiceCollection {
    const newDiceCollection = new DiceCollection()
    newDiceCollection.dice = { ...this.dice }
    newDiceCollection.modifier = this.modifier
    return newDiceCollection
  }

  addDice(dice: Dice) {
    if (this.dice[dice.diceValue]) {
      this.dice[dice.diceValue] += dice.diceCount
    } else {
      this.dice[dice.diceValue] = dice.diceCount
    }
    this.modifier += dice.fixedValue
    return this
  }

  addDiceCollection(diceCollection: DiceCollection): DiceCollection {
    for (const die in diceCollection.dice) {
      if (this.dice[die]) {
        this.dice[die] += diceCollection.dice[die]
      } else {
        this.dice[die] = diceCollection.dice[die]
      }
    }

    this.modifier += diceCollection.modifier
    return this
  }

  addDiceList(diceList: Dice[]) {
    for (const die of diceList) {
      this.addDice(die)
    }
    return this
  }

  multiplyDice(diceMultiplier: number, modifierMultiplier: number = 1): DiceCollection {
    for (const die in this.dice) {
      this.dice[die] = this.dice[die] * diceMultiplier
    }

    this.modifier *= modifierMultiplier
    return this
  }

  maxDice(): DiceCollection {
    const { dice, modifier } = this
    const total = Object.entries(dice).reduce((acc, [die, count]) => acc + parseInt(die) * count, modifier)
    this.dice = {}
    this.modifier = total
    return this
  }

  modifierString(modifier: number): string {
    if (modifier >= 0) {
      return '+' + modifier.toString()
    }
    return modifier.toString()
  }

  displayString(rerollThreshold: number = 0, putConstantInFront: boolean = false, minRoll: number = 0): string {
    const consolidated = this.dice
    const modifierSum = this.modifier

    const noDice: boolean = Object.keys(this.dice).length === 0
    if (noDice) {
      return putConstantInFront ? this.modifierString(modifierSum) : String(modifierSum)
    }

    const sortedKeys = Object.keys(consolidated)
      .filter((key) => parseInt(key) > 0)
      .sort((a, b) => parseInt(b.slice(1)) - parseInt(a.slice(1)))
      .reverse()

    const minRollPrefix = minRoll > 0 ? `(${minRoll}>` : ''
    const minRollSuffix = minRoll > 0 ? ')' : ''

    let consolidatedString = sortedKeys
      .map((key) => `${consolidated[key]}${minRollPrefix}d${key}${minRollSuffix}`)
      .join(' + ')

    if (this.dice.length === 0) {
      return String(this.modifier)
    }

    if (putConstantInFront) {
      const sumString = this.modifierString(modifierSum)
      // +3

      if (consolidatedString.length === 0) {
        return this.modifierString(modifierSum)
      }

      // +3 + 1d8 + 1d6
      if (modifierSum !== 0 && consolidatedString.length > 0) {
        return sumString + ' + ' + consolidatedString
      }
    }

    if (rerollThreshold) {
      //   if (sortedKeys.length !== 1) {
      //     console.error(
      //       `Assumption false: We are trying to reroll something with multiple sets of base damage dice ${consolidatedString}`
      //     )
      //   } else {
      const diceValue = sortedKeys[0]
      const diceCount = consolidated[diceValue]
      consolidatedString = `${diceCount}${minRollPrefix}(d${diceValue} reroll ${rerollThreshold})${minRollSuffix}`
      //   }
    }

    if (consolidatedString === '') {
      return String(modifierSum)
    }

    if (modifierSum > 0) {
      return `${consolidatedString} + ${modifierSum}`
    } else if (modifierSum < 0) {
      return `${consolidatedString} - ${Math.abs(modifierSum)}`
    }
    return consolidatedString
  }

  diceSumFrequency(): NumberMap {
    const diceList: number[][] = []
    diceList.push([this.modifier])
    for (const die in this.dice) {
      const diceCount = this.dice[die]
      const diceValue = parseInt(die)
      for (let i = 0; i < diceCount; i++) {
        diceList.push(DiceUtility.diceFaceList(diceValue))
      }
    }

    const combinations = this.diceSumMultiplier(diceList)
    const frequencyMap: NumberMap = new NumberMap()

    combinations.forEach((num) => {
      if (frequencyMap[num]) {
        frequencyMap[num]++
      } else {
        frequencyMap[num] = 1
      }
    })
    return frequencyMap
  }

  private diceSumMultiplier(diceList: number[][]): number[] {
    function _faceMultiply(a: number[], b: number[]): number[] {
      const combinations: number[] = []
      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
          combinations.push(a[i] + b[j])
        }
      }
      return combinations
    }
    let combinations = [0]
    for (const dice of diceList) {
      combinations = _faceMultiply(combinations, dice)
    }
    return combinations
  }
}
