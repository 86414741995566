import { Dictionary } from '../Common/Types'

export class FightingStyle {
  name: string
  snippet: string
  id: number

  constructor(data: Dictionary) {
    this.name = data.name
    this.snippet = data.snippet
    this.id = data.id
  }
}
