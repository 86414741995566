import { Dictionary } from '../Common/Types'
import { Range } from './Range'
import { Character } from './Character'
import { AttackAction } from './AttackAction'
import { Activation } from './Activation'
import { Dice } from './Dice'
import { Utility } from '../Common/Utility'
import { ABILITIES, COMPANION, SPELL, SPELL_ATTACK, WEAPON } from '../Common/Constants'
import { Class } from '../Common/Constants'

export class ClassActionFactory {
  character: Character

  constructor(character: Character) {
    this.character = character
  }

  parseAction(action: Dictionary, attackActions: AttackAction[]) {
    let newAction = undefined
    const name = action.name
    const meleeAttackRange = Range.makeWeaponRange(5)
    const companionAttrs = { range: meleeAttackRange, type: COMPANION }
    const { proficiencyBonus, spellAttackModifier: spellAttackMod } = this.character
    const spellcastingAbilityModifier = this.character.spellcastingAbilityModifier
    const ba = Activation.BonusAction()
    const a = Activation.Action()
    const spellSaveDC = this.character.spellSaveDC

    if (name === 'Radiant Sun Bolt') {
      const unarmedStrikeDice = Dice.Create(1, this.character.monkDieSize())
      newAction = this.createUnarmedStrikeAttackAction(name, a, action.id, unarmedStrikeDice, 1, true)
      newAction.attributes.radiantSunBolt = true
      newAction.attributes.effectCount = 1
      newAction.attributes.effectCountLabel = 'Bolts'
    } else if (name === 'Flurry of Blows') {
      const unarmedStrikeDice = Dice.Create(1, this.character.monkDieSize())
      newAction = this.createUnarmedStrikeAttackAction(name, ba, action.id, unarmedStrikeDice, 1)
      newAction.attributes.flurryOfBlows = true
      newAction.attributes.effectCount = 2
      newAction.attributes.effectCountLabel = 'Blows'
    } else if (name === 'Form of the Beast: Bite') {
      newAction = this.createWeaponAttackAction(name, action, 5)
    } else if (name === 'Form of the Beast: Claws') {
      // TODO - don't forget to add a bonus attack if this is selected as a feature!
      newAction = this.createWeaponAttackAction(name, action, 5)
    } else if (name === 'Form of the Beast: Tail') {
      newAction = this.createWeaponAttackAction(name, action, 10)
    } else if (
      name === 'Eldritch Cannon: Flamethrower' ||
      name === 'Detonate Eldritch Cannon' ||
      name === 'Drake’s Breath'
    ) {
      newAction = this.createAoeSpellAction(name, action, ba)
    } else if (name === 'Augment Breath') {
      action.range.range = 60 // TODO: AOE cone
      newAction = this.createAoeSpellAction('Breath of the Dragon: Augment Breath', action, a)
    } else if (name === 'Breath of the Dragon') {
      action.range.range = 20 // TODO: AOE cone
      newAction = this.createAoeSpellAction(name, action, a)
    } else if (name === 'Arms of the Astral Self: Summon') {
      action.range.range = 10
      newAction = this.createAoeSpellAction(name, action, ba)
    } else if (name === 'Explosive Fury') {
      console.log('explosive fury not yet implemented', action.range)
      newAction = this.createAoeSpellAction(name, action, a)
    } else if (name === 'Warping Implosion') {
      newAction = this.createAoeSpellAction(name, action, a)
    } else if (name === 'Necrotic Husk: Revival') {
      // TODO: Make this a Reaction, not bonus action, once we support that
      newAction = this.createAoeSpellAction(name, action, ba, false)
    } else if (name === 'Eldritch Cannon: Force Ballista') {
      const attributes = {
        range: Range.makeSpellAttackRange(parseInt(action.range.range)),
        type: SPELL_ATTACK,
        requiresAttackRoll: true
      }
      const abilityMod = this.character.modifierForAbilityIndex(parseInt(action.abilityModifierStatId) - 1)
      const dice = new Dice(action.dice)
      dice.fixedValue += abilityMod
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, ba)
    } else if (name === 'Tentacle of the Deeps: Attack') {
      newAction = this.createWeaponAttackAction(name, action, action.range.range)
    } else if (name === 'Steel Defender') {
      const dice = Dice.Create(1, 8, this.character.proficiencyBonus) // TODO - scale with level
      newAction = new AttackAction(action.id, 'Force-Empowered Rend', spellAttackMod, dice, companionAttrs, ba)
    } else if (name === 'Hound of Ill Omen') {
      const dice = Dice.Create(2, 6, 3)
      newAction = new AttackAction(action.id, 'Hound of Ill Omen: Bite', 5, dice, companionAttrs, ba)
    } else if (name === 'Psychic Blades: Attack (DEX)') {
      newAction = this.createPsychicBlades(action, ABILITIES.indexOf('dexterity'))
    } else if (name === 'Psychic Blades: Bonus Attack (DEX)') {
      newAction = this.createPsychicBlades(action, ABILITIES.indexOf('dexterity'))
    } else if (name === 'Psychic Blades: Attack (STR)') {
      newAction = this.createPsychicBlades(action, ABILITIES.indexOf('strength'))
    } else if (name === 'Psychic Blades: Bonus Attack (STR)') {
      newAction = this.createPsychicBlades(action, ABILITIES.indexOf('strength'))
    } else if (name === 'Psychic Blades') {
      // 2024 Soulknife
      const d6 = Dice.Create(1, 6)
      const d4 = Dice.Create(1, 4)
      newAction = this.createSoulknifePsychicBlades(action.id, d6, action, a)

      const bonusAction = this.createSoulknifePsychicBlades(action.id + 1000000, d4, action, ba)
      attackActions.push(bonusAction)
    } else if (name === 'Summon Wildfire Spirit: Command') {
      const spellAttackRange = Range.makeSpellAttackRange(15)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(1),
        saveDcValue: spellSaveDC
      }
      const dice = Dice.Create(1, 6, proficiencyBonus)
      const ftName = 'Fiery Teleportation'
      newAction = new AttackAction(action.id, ftName, spellAttackMod, dice, attributes, ba)
    } else if (name === 'Summon Wildfire Spirit') {
      const rangedAttackRange = Range.makeWeaponRange(60)
      const fsAttrs = { range: rangedAttackRange, type: WEAPON }
      const fsDice = Dice.Create(1, 6, proficiencyBonus)
      const fsName = 'Wildfire Spirit: Flame Seed'
      newAction = new AttackAction(action.id, fsName, spellAttackMod, fsDice, fsAttrs, ba)
    } else if (name === 'Spreading Spores') {
      const sporeDamage = this.character.sporeDamageDie()
      const range = Range.makeSpellAttackRange(parseInt(action.range.range))
      const attributes = {
        range: range,
        type: SPELL,
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }
      newAction = new AttackAction(action.id, name, spellAttackMod, sporeDamage, attributes, ba)
    } else if (name === 'Cauterizing Flames') {
      const spellAttackRange = Range.makeSpellAttackRange(0)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }
      const wisdomMod = this.character.modifierForAbility('wisdom')
      const dice = Dice.Create(2, 10, wisdomMod)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, ba)
    } else if (name === 'Fungal Infestation') {
      const toHit = 3
      const dice = Dice.Create(1, 6, 1)
      newAction = new AttackAction(action.id, 'Fungal Zombie Slam', toHit, dice, companionAttrs, ba)
    } else if (name === 'Drake Companion') {
      const toHit = 3 + proficiencyBonus
      const level = this.character.classLevel(Class.RANGER)
      const diceCount = 1 + (level >= 7 ? 1 : 0) + (level >= 15 ? 1 : 0)
      const dice = Dice.Create(diceCount, 6, proficiencyBonus)
      newAction = new AttackAction(action.id, 'Drake Companion: Bite', toHit, dice, companionAttrs, ba)
    } else if (name === 'Starry Form: Archer') {
      const spellAttackRange = Range.makeSpellAttackRange(parseInt(action.range.range))
      const attributes = { range: spellAttackRange, type: SPELL_ATTACK }
      const druidLevel = this.character.classLevel(Class.DRUID)
      const dice = Dice.Create(druidLevel >= 10 ? 2 : 1, 8, spellcastingAbilityModifier)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, ba)
    } else if (name === 'Channel Divinity: Radiance of the Dawn') {
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId - 1),
        damageTypes: ['radiant'],
        saveDcValue: spellSaveDC
      }

      const dice = new Dice(action.dice)

      if (action.id === '9414166' || action.id === 9414167) {
        // 2024 Radiance of the Dawn deals 2d10+level damage
        dice.fixedValue = this.character.classLevel(Class.CLERIC)
      }
      const activation = new Activation(action.activation)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, activation)
    } else if (name === 'Channel Divinity: Sear Undead') {
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(4),
        saveDcValue: spellSaveDC
      }

      const dice = new Dice(action.dice)
      dice.diceCount = this.character.spellcastingAbilityModifier
      const activation = new Activation(action.activation)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, activation)
    } else if (name === 'Channel Divinity: Divine Spark') {
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(2),
        saveDcValue: spellSaveDC
      }

      const dice = new Dice(action.dice)
      dice.diceCount = this.character.clericDivineSparkDieCount()
      dice.fixedValue = this.character.modifierForAbility('wisdom')
      const activation = new Activation(action.activation)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, activation)
    } else if (name === 'Accursed Specter') {
      const lifeDrain = 'Specter: Life Drain'
      const foundSpecter: AttackAction | undefined = attackActions.find((action) => action.name === lifeDrain)
      if (foundSpecter) {
        foundSpecter.attackMod = 4 + spellcastingAbilityModifier
      } else {
        const toHit = 4 + spellcastingAbilityModifier
        const dice = Dice.Create(3, 6)
        newAction = new AttackAction(action.id, lifeDrain, toHit, dice, companionAttrs, ba)
      }
    } else if (name === 'Storm Aura: Sea') {
      // TODO - Look at radiance of the dawn for a spell version of this
      // When this effect is activated, you can choose one other creature you can see in your aura.
      // The target must make a Dexterity saving throw.The target takes 1d6 lightning damage on a failed save,
      // or half as much damage on a successful one.The damage increases when you reach certain levels in this class,
      // increasing to 2d6 at 10th level, 3d6 at 15th level, and 4d6 at 20th level.
      //   const barbarianLevel = this.classLevel(Class.BARBARIAN)
      //   const diceCount = barbarianLevel >= 10 ? 1 + Math.floor((barbarianLevel - 5) / 5) : 1
      //   const dice = Dice.Create(diceCount, 6)
      //   newAction = new AttackAction(action.id, )
    } else if (name === 'Animating Performance: Dancing Item') {
      const dice = Dice.Create(1, 10, proficiencyBonus)
      const slam = 'Dancing Item: Force-Empowered Slam'
      newAction = new AttackAction(action.id, slam, spellAttackMod, dice, companionAttrs, ba)
    } else if (name === 'Guardian Armor: Thunder Gauntlets') {
      newAction = this.createWeaponAttackAction(name, action, 5)
    } else if (name === 'Guardian Armor: Thunder Gauntlets (STR)') {
      newAction = this.createWeaponAttackAction(name, action, 5)
    } else if (name === 'Infiltrator Armor: Lightning Launcher') {
      newAction = this.createWeaponAttackAction(name, action, 90)
      newAction.attributes.range = Range.makeWeaponRange(90, 300)
    } else if (name === 'Infiltrator Armor: Lightning Launcher (DEX)') {
      newAction = this.createWeaponAttackAction(name, action, 90)
      newAction.attributes.range = Range.makeWeaponRange(90, 300)
    } else if (name === 'Arms of the Astral Self (DEX/STR)') {
      const unarmedStrikeDice = Dice.Create(1, this.character.monkDieSize())
      newAction = this.createWeaponAttackAction(name, action, 5, unarmedStrikeDice)
      newAction.attributes.astralArms = true
      newAction.attributes.subType = 'unarmed'
    } else if (name === 'Arms of the Astral Self (WIS)') {
      const unarmedStrikeDice = Dice.Create(1, this.character.monkDieSize())
      newAction = this.createWeaponAttackAction(name, action, 5, unarmedStrikeDice)
      newAction.attributes.astralArms = true
      newAction.attributes.subType = 'unarmed'
    } else if (name === 'Touch of the Long Death') {
      for (let ki = 1; ki <= 10; ki++) {
        const spellAttackRange = Range.makeSpellAttackRange(0)
        const attributes = {
          range: spellAttackRange,
          type: SPELL,
          requiresSavingThrow: true,
          requiresAttackRoll: false,
          saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId - 1),
          saveDcValue: spellSaveDC,
          subType: 'unarmed'
        }

        const dice = new Dice(action.dice)
        dice.diceCount *= ki
        const activation = new Activation(action.activation)
        const actionName = `${name} (${Utility.kiPointsString(ki)})`

        const longDeathAction = new AttackAction(
          action.id + ki,
          actionName,
          spellAttackMod,
          dice,
          attributes,
          activation
        )
        longDeathAction.attributes.subType = 'unarmed'
        attackActions.push(longDeathAction)
      }
    } else if (name === 'Maddening Hex') {
      // TODO - later this can scale with number of enemies around the hexed target
      const charismaMod = this.character.modifierForAbility('charisma')
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }
      const dice = Dice.flatAmountDie(charismaMod)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, ba)
    } else if (name === 'Searing Sunburst') {
      // Todo this is save or suck, not save for half
      for (let ki = 1; ki <= 3; ki++) {
        const attributes = {
          range: new Range(action.range),
          type: SPELL,
          requiresSavingThrow: true,
          requiresAttackRoll: false,
          saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId - 1),
          saveDcValue: spellSaveDC
        }
        const dice = new Dice(action.dice)
        dice.diceCount *= ki
        const activation = new Activation(action.activation)
        const actionName = `${name} (${Utility.kiPointsString(ki)})`
        const sunburst = new AttackAction(action.id + ki, actionName, spellAttackMod, dice, attributes, activation)
        attackActions.push(sunburst)
      }
    } else if (name === 'Sun Shield') {
      const attributes = {
        range: new Range(action.range),
        type: SPELL,
        requiresSavingThrow: false,
        requiresAttackRoll: false
      }
      const wisdomMod = this.character.modifierForAbility('wisdom')
      const dice = Dice.flatAmountDie(5 + wisdomMod)
      const activation = new Activation(action.activation)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, activation)
    } else if (name === 'Fist of Unbroken Air') {
      for (let ki = 2; ki <= this.character.maxKiPointsForMonkSpell(); ki++) {
        const activation = new Activation(action.activation)
        const dice = new Dice(action.dice)
        dice.diceCount += ki - 2
        const actionName = `${name} (${Utility.kiPointsString(ki)})`
        const fist = this.createUnarmedStrikeAttackAction(actionName, activation, action.id + ki - 2, dice, 0)
        attackActions.push(fist)
      }
    } else if (name === 'Water Whip') {
      for (let ki = 2; ki <= this.character.maxKiPointsForMonkSpell(); ki++) {
        const attributes = {
          range: Range.makeSpellAttackRange(action.range.range),
          type: SPELL,
          requiresSavingThrow: true,
          requiresAttackRoll: false,
          saveDcAbility: Utility.shortNameForAbilityID(action.saveStatId - 1),
          saveDcValue: spellSaveDC
        }
        const dice = new Dice(action.dice)
        dice.diceCount += ki - 2
        const activation = new Activation(action.activation)
        const actionName = `${name} (${Utility.kiPointsString(ki)})`
        const whip = new AttackAction(action.id + ki, actionName, spellAttackMod, dice, attributes, activation)
        attackActions.push(whip)
      }
    } else if (name === 'Manifest Wrath of the Sea') {
      // TODO this isn't a 'save half' one…
      const wisdomMod = this.character.modifierForAbility('wisdom')
      const spellAttackRange = Range.makeSpellAttackRange(30)
      const attributes = {
        range: spellAttackRange,
        attackMod: 0,
        type: SPELL,
        requiresSavingThrow: true,
        requiresAttackRoll: false,
        saveDcAbility: Utility.shortNameForAbilityID(2),
        saveDcValue: spellSaveDC
      }

      const dice = Dice.Create(wisdomMod, 6)

      const activation = new Activation(action.activation)
      newAction = new AttackAction(action.id, name, spellAttackMod, dice, attributes, activation)
    }

    return newAction
  }

  createBreathWeaponAction(id: number, action: Dictionary, range: Range) {
    const saveDCAbility = Utility.shortNameForAbilityID(action.saveStatId - 1)
    const spellSaveDC = this.character.spellSaveDC
    const attributes = {
      range: range,
      type: SPELL_ATTACK,
      requiresSavingThrow: true,
      requiresAttackRoll: false,
      saveDcAbility: saveDCAbility,
      saveDcValue: spellSaveDC
    }

    return new AttackAction(id, action.name, 0, new Dice(action.dice), attributes, new Activation(action.activation))
  }

  createAoeSpellAction(name: string, action: Dictionary, activation: Activation, saveRequired: boolean = true) {
    const range = Range.makeWeaponRange(parseInt(action.range.range))
    range.aoeSize = parseInt(action.range.aoeSize)
    range.aoeType = action.range.aoeType
    const saveDCAbility = saveRequired ? Utility.shortNameForAbilityID(action.saveStatId - 1) : null
    const spellSaveDC = this.character.spellSaveDC
    const attributes = {
      range: range,
      type: WEAPON,
      requiresSavingThrow: saveRequired,
      requiresAttackRoll: false,
      saveDcAbility: saveDCAbility,
      saveDcValue: spellSaveDC
    }

    return new AttackAction(parseInt(action.id), name, 0, new Dice(action.dice), attributes, activation)
  }

  private finesseAbilityMod() {
    const str = this.character.abilityScoreForIndex(ABILITIES.indexOf('strength'))
    const dex = this.character.abilityScoreForIndex(ABILITIES.indexOf('dexterity'))
    return Utility.modifierForScore(Math.max(str, dex))
  }

  createSoulknifePsychicBlades(id: number, dice: Dice, action: Dictionary, activation: Activation): AttackAction {
    const name: string = action.name
    const abilityMod = this.finesseAbilityMod()
    const toHit = this.character.proficiencyBonus + abilityMod
    const attackRange = Range.makeWeaponRange(60)
    const attributes = this.thrownfinesseWeaponAttrs(attackRange)
    attributes.psychicBlades = true
    // if (activation.usesAction()) { // TODO determine if this is RAW
    dice.fixedValue += abilityMod

    return new AttackAction(id, name, toHit, dice, attributes, activation)
  }

  createPsychicBlades(action: Dictionary, abilityIndex: number): AttackAction {
    const id: number = action.id
    const name: string = action.name
    const abilityMod = Utility.modifierForScore(this.character.abilityScoreForIndex(abilityIndex))
    const toHit = this.character.proficiencyBonus + abilityMod

    const attackRange = Range.makeWeaponRange(60)
    const attributes = this.thrownfinesseWeaponAttrs(attackRange)
    const dice: Dice = new Dice(action.dice)
    dice.fixedValue += abilityMod
    const activation: Activation = new Activation(action.activation)
    return new AttackAction(id, name, toHit, dice, attributes, activation)
  }

  // TODO clean all of these up
  //     createLightningArrow(die: Dice, activation: Activation): AttackAction {

  //     // const abilityMod = this.finesseAbilityMod()
  //     // const toHit = this.character.proficiencyBonus + abilityMod
  //     // die.fixedValue += abilityMod
  //     // const attackRange = Range.makeWeaponRange(60)
  //     // const attributes = this.thrownfinesseWeaponAttrs(attackRange)
  //     // return new AttackAction(id, weaponName, toHit, die, attributes, activation)
  //   }

  thrownfinesseWeaponAttrs(range: Range): Dictionary {
    return {
      range: range,
      type: WEAPON,
      isFinesse: true,
      isThrown: true
    }
  }

  createShadowBlades(action: Dictionary, die: Dice, activation: Activation): AttackAction {
    const attackRange = new Range(action.range)
    const abilityMod = this.finesseAbilityMod()
    const toHit = this.character.proficiencyBonus + abilityMod
    die.fixedValue += abilityMod
    const attributes = this.thrownfinesseWeaponAttrs(attackRange)
    return new AttackAction(action.id, action.name, toHit, die, attributes, activation)
  }

  createWeaponAttackAction(
    name: string,
    action: Dictionary,
    range: number,
    diceOverride: Dice | undefined = undefined
  ): AttackAction {
    const { abilityModifierStatId, dice, id, activation } = action
    const attackRange = Range.makeWeaponRange(range)
    const abilityMod = this.character.modifierForAbilityIndex(parseInt(abilityModifierStatId) - 1)
    const toHit = this.character.proficiencyBonus + abilityMod
    const damageDice = diceOverride ? diceOverride : new Dice(dice)
    damageDice.fixedValue += abilityMod
    const attributes = { range: attackRange, type: WEAPON }
    return new AttackAction(id, name, toHit, damageDice, attributes, new Activation(activation))
  }

  createBasicUnarmedStrikeAttackAction(definition: Dictionary, dice: Dice): AttackAction {
    return this.createUnarmedStrikeAttackAction(definition.name, Activation.Action(), definition.id, dice)
  }

  createUnarmedStrikeAttackAction(
    name: string,
    activation: Activation,
    id: number,
    dice: Dice,
    modMultiplier: number = 1,
    dexOverride: boolean = false
  ): AttackAction {
    let abilityModifierStatId = dexOverride ? ABILITIES.indexOf('dexterity') : ABILITIES.indexOf('strength')
    if (this.character.classLevel(Class.MONK) > 0) {
      if (this.character.dexterity() > this.character.strength()) {
        abilityModifierStatId = ABILITIES.indexOf('dexterity')
      }

      // TODO - update the monk die logic in Weapon to use this
    }

    const attackRange = Range.makeWeaponRange(5) // TODO: Racial modifier for reach would go here, if we ever care about reach
    const abilityMod = this.character.modifierForAbilityIndex(abilityModifierStatId)
    const toHit = this.character.proficiencyBonus + abilityMod
    const damageDice = new Dice(dice)
    damageDice.fixedValue += abilityMod * modMultiplier
    const attributes = { range: attackRange, type: WEAPON, subType: 'unarmed' }
    const newAction = new AttackAction(id, name, toHit, damageDice, attributes, new Activation(activation))
    newAction.attributes.subType = 'unarmed'
    return newAction
  }
}
