import React from 'react'
import { Box, SimpleGrid, Skeleton, SkeletonText } from '@chakra-ui/react'

export function EmptyCharacterPage() {
  const spacing = 2
  const padding = 4
  return (
    <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={spacing}>
      <Box height={250} p={padding} bgColor={'bg.surface'}>
        <Skeleton height="100%" speed={0.8} />
      </Box>

      <Box height={250} bgColor={'bg.surface'} p={padding}>
        <Skeleton height="100%" speed={0.9} />
      </Box>

      <Box p={padding} bgColor={'bg.surface'}>
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="4" pb={4} pt={0} />
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="4" pb={4} />
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="4" pb={4} />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="4" pb={4} />
      </Box>

      <Box p={padding} bgColor={'bg.surface'}>
        <SkeletonText mt="4" noOfLines={3} spacing="4" skeletonHeight="4" pb={4} pt={0} />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="4" pb={4} />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="4" pb={4} />
        <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="4" pb={4} />
      </Box>
    </SimpleGrid>
  )
}
