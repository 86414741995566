import React from 'react'
import { DprTab, MainNavBar } from './MainNavBar'
import { Container, Heading, Stack, Text } from '@chakra-ui/react'
import { TrackPageView, PAGE_ABOUT } from '../Common/AppAnalytics'

const accentColor = 'blue.200'

export function AboutPage() {
  const title = 'About'
  const description = 'Coming soon'
  TrackPageView(PAGE_ABOUT)

  const element = (
    <Container py={{ base: '8', md: '12' }}>
      <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
        <Stack spacing={{ base: '12', md: '16' }}>
          <Stack spacing={{ base: '4', md: '5' }}>
            <Stack spacing="3">
              <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight="semibold" color={accentColor}>
                About
              </Text>
              <Heading size={{ base: 'sm', md: 'md' }}>{title}</Heading>
            </Stack>
            <Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
              {description}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )

  return <MainNavBar tab={DprTab.ABOUT} body={element} />
}
