import { DDBLoader } from '../DDB/DDBLoader'

export class Loader {
  private static instance: Loader
  loadingPromises: { [id: number]: Promise<any> } = {}

  static sharedLoader(): Loader {
    if (!Loader.instance) {
      Loader.instance = new Loader()
    }

    return Loader.instance
  }

  fetchUrl(url: string, reload: boolean, callback: (data: any, error: any) => void): Promise<any> {
    return fetch(url, {
      cache: reload ? 'reload' : 'force-cache'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        return response.json()
      })
      .then((data) => {
        callback(data, null)
      })
      .catch((error) => {
        console.error('Failed to fetch data:', error)
        callback(null, error)
      })
  }

  fetchCharacter(
    cid: number,
    reload: boolean,
    characterCallback: (data: any) => void,
    errorHandler: (error: any) => void
  ): void {
    const url = `/api/character?cid=${cid}`

    if (typeof this.loadingPromises[cid] !== 'undefined') {
      // If a promise for this character ID already exists, attach a new .then() to it
      this.loadingPromises[cid].then(characterCallback).catch((error) => {
        console.error('Failed to fetch data:', error)
        characterCallback(null)
      })
    } else {
      // If a promise for this character ID doesn't exist, create a new one
      this.loadingPromises[cid] = this.fetchUrl(url, reload, (data: any, error: any) => {
        if (data !== null) {
          DDBLoader.load(data, characterCallback)
        } else {
          errorHandler(error)
        }
      }).finally(() => {
        // Once the promise is settled, remove it from the map
        delete this.loadingPromises[cid]
      })

      // Attach a .then() to the new promise
      this.loadingPromises[cid].then(characterCallback).catch((error) => {
        console.error('Failed to fetch data:', error)
        errorHandler(error)
      })
    }
  }

  fetchAlwaysPreparedSpells(
    classId: number,
    classLevel: number,
    reload: boolean,
    callback: (data: any, error: any) => void
  ): void {
    const url = `/api/always-prepared-spells?classId=${classId}&classLevel=${classLevel}`
    this.fetchUrl(url, reload, callback)
  }

  fetchAlwaysKnownSpells(
    classId: number,
    classLevel: number,
    backgroundId: number,
    reload: boolean,
    callback: (data: any, error: any) => void
  ): void {
    const url = `/api/always-known-spells?classId=${classId}&classLevel=${classLevel}&backgroundId=${backgroundId}`
    this.fetchUrl(url, reload, callback)
  }
}
