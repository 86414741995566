import { ACTION, BONUS_ACTION, REACTION } from '../Common/Constants'
import { Dictionary } from '../Common/Types'

export class Activation {
  activationType: number

  constructor(activation: Dictionary) {
    this.activationType = activation.activationType
  }

  static Action() {
    return new Activation({ activationType: ACTION })
  }

  static BonusAction() {
    return new Activation({ activationType: BONUS_ACTION })
  }

  static Reaction() {
    return new Activation({ activationType: REACTION })
  }

  static None() {
    return new Activation({ activationType: 0 })
  }

  usesAction(): boolean {
    return this.activationType === ACTION
  }

  usesBonusAction(): boolean {
    return this.activationType === BONUS_ACTION
  }

  usesReaction(): boolean {
    return this.activationType === REACTION
  }

  activationTypeString(): string {
    if (this.usesAction()) {
      return 'Action'
    }
    if (this.usesBonusAction()) {
      return 'Bonus Action'
    }
    if (this.usesReaction()) {
      return 'Reaction'
    }
    return 'None'
  }
}
