import React from 'react'
import { CharacterInfo } from '../DDB/CharacterInfo'
import { CharacterSheet } from './CharacterSheet'
import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { Loader } from '../Loader/Loader'
import { Dispatcher } from '../Common/Types'
import { Campaign } from '../DDB/Campaign'
import { useEffect } from 'react'
import { FailureToLoadPage } from './FailureToLoadPage'
import { EmptyCharacterPage } from './EmptyCharacterPage'
import { TrackCharacter } from '../Common/AppAnalytics'

export function CharacterPage({
  id,
  isPrimary,
  setCampaign,
  setCharacterName
}: {
  id: number
  isPrimary: boolean
  setCampaign: Dispatcher<Campaign>
  setCharacterName: Dispatcher<string>
}) {
  const [reload, setReload] = useState(false)
  const [characterInfo, setCharacterInfo] = useState<CharacterInfo | null>(null)
  const [loadError, setLoadError] = useState(false)

  useEffect(() => {
    if (reload || !characterInfo || (characterInfo && characterInfo.id !== id)) {
      Loader.sharedLoader().fetchCharacter(
        id,
        reload,
        (info: CharacterInfo) => {
          setReload(false)
          if (info) {
            if (isPrimary) {
              setCampaign(info.campaign)
              setCharacterName(info.name)
              document.title = `DPR Calculator${info ? ` – ${info.name}` : ''}`
              TrackCharacter(info)
            }

            setCharacterInfo(info)
            setLoadError(false)
          } else {
            // TODO figure out why this gets called a lot? Something to do with promises.
          }
        },
        (error) => {
          console.error(`Could not load ${id}`, error)
          setLoadError(true)
          setCharacterInfo(null)
        }
      )
    }
  }, [characterInfo, id, isPrimary, reload, setCampaign, setCharacterName, loadError])

  return (
    <Box alignItems="flex-start" key={characterInfo ? characterInfo.name : 'Loading'} p={2}>
      {loadError && <FailureToLoadPage id={id} setReload={setReload} />}
      {characterInfo && !loadError && (
        <CharacterSheet
          character={characterInfo}
          key={characterInfo.url}
          reload={reload}
          setReload={setReload}
          isPrimary={isPrimary}
        />
      )}
      {!characterInfo && !loadError && <EmptyCharacterPage />}

      {/* {!characterInfo && !loadError && <Box p={4}>Please refresh the page one more time.</Box>} */}
    </Box>
  )
}
