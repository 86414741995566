import { Container, HStack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Loader } from '../Loader/Loader'
import { CharacterInfo } from '../DDB/CharacterInfo'
import { Dictionary } from '../Common/Types'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { CheckCircleIcon, CloseIcon, SpinnerIcon } from '@chakra-ui/icons'
import { Utility } from '../Common/Utility'
import { TurnEngine } from '../Data/TurnEngine'
import { sumDamageArrays } from '../Data/ChartData'
import { Link } from '@chakra-ui/react'
import { LoadDPRTestData } from '../Test/DPRTestData'

// TODO - add timing to the results damage calculation, so we can use this as a benchmark too!

enum DPRStatus {
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  PASS = 'PASS',
  FAIL = 'FAIL'
}

function loadCharacter(cid: number, callback: (info: CharacterInfo | null, error: Dictionary | null) => void) {
  Loader.sharedLoader().fetchCharacter(
    cid,
    false, // false = USE CACHE
    (info: CharacterInfo) => {
      callback(info, null)
    },
    (error) => {
      console.error(`Could not load ${cid}`, error)
      callback(null, error)
    }
  )
}

class CharacterTestResult {
  id: number
  testData: Dictionary

  info: CharacterInfo | null
  error: Dictionary | null
  duration: number | null

  targetDPR: string
  calculatedDPR: string | null = null

  private turnEngine: TurnEngine | null = null

  constructor(
    testData: Dictionary,
    info: CharacterInfo | null = null,
    error: Dictionary | null = null,
    duration: number | null = null
  ) {
    this.id = testData.data.cid
    this.targetDPR = testData.data.dpr
    this.testData = testData
    this.info = info
    this.error = error
    this.duration = duration

    if (this.info) {
      const acs = Utility.range(5, 30)
      const { checkedFeatures, actionIdList, advantageOverrides, targetAC } = this.testData.data
      this.turnEngine = new TurnEngine(this.info, checkedFeatures, actionIdList, advantageOverrides, acs, targetAC)

      const allTurns = this.turnEngine.allTurnActions
      const damageTotals = sumDamageArrays(allTurns, [targetAC])
      this.calculatedDPR = damageTotals[0].toFixed(2)
    }
  }

  shareURL(): string {
    return this.turnEngine?.shareableURL() || ''
  }
  DPRStatus(): DPRStatus {
    if (this.error) {
      return DPRStatus.ERROR
    }

    if (this.calculatedDPR === null) {
      return DPRStatus.LOADING
    }

    if (this.targetDPR === this.calculatedDPR) {
      return DPRStatus.PASS
    }

    return DPRStatus.FAIL
  }

  DPRStatusText(): JSX.Element {
    switch (this.DPRStatus()) {
      case DPRStatus.ERROR:
        return (
          <HStack>
            <CloseIcon color="red.500" />
            <Text>{`Error: ${this.error}`}</Text>
          </HStack>
        )
      case DPRStatus.LOADING:
        return (
          <HStack>
            <SpinnerIcon color="grey.500" />
            <Text>Loading…</Text>
          </HStack>
        )
      case DPRStatus.PASS:
        return (
          <HStack>
            <CheckCircleIcon color="green.500" />
            <Text>{this.targetDPR}</Text>
          </HStack>
        )
      case DPRStatus.FAIL:
        return (
          <HStack>
            <CloseIcon color="red.500" />
            <Text>{`${this.targetDPR} != ${this.calculatedDPR}`}</Text>
          </HStack>
        )
    }
  }
}

export function TestPage() {
  const testData: Dictionary[] = LoadDPRTestData()

  const initialTestResults = testData.map((data) => new CharacterTestResult(data))
  const [testResults, setTestResults] = useState<CharacterTestResult[]>(initialTestResults)

  useEffect(() => {
    testResults.forEach((result, index) => {
      setTimeout(() => {
        const start = performance.now()

        loadCharacter(result.id, (info, error) => {
          if (info || error) {
            const end = performance.now()

            setTestResults((prevResults) =>
              prevResults.map((r) =>
                r.id === result.id ? new CharacterTestResult(result.testData, info, error, end - start) : r
              )
            )
          }
        })
      }, index * 100)
    })
  }, [])

  return (
    <Container>
      <Text>Testing</Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Character</Th>
            <Th>Status</Th>
            <Th>Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {testResults.map((result) => {
            return (
              <Tr key={result.id}>
                <Td>
                  {result?.info ? (
                    <Link href={result.shareURL()} isExternal>
                      {result.info.name} ({result.id})
                    </Link>
                  ) : (
                    'Loading…'
                  )}
                </Td>
                <Td>{result.DPRStatusText()}</Td>
                <Td whiteSpace="normal">{result?.info ? result.info.testDescriptorForDisplay() : '…'}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </Container>
  )
}
