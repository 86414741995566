export function DPRAppThemeComponents() {
  const Menu = MenuThemeCommponent()
  const Table = TableThemeComponent()

  return {
    Menu,
    Table
  }
}

function TableThemeComponent() {
  return {
    parts: ['th'],
    baseStyle: {
      th: {
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    }
  }
}

function MenuThemeCommponent() {
  return {
    baseStyle: {
      variants: {
        hoverButton: {
          bgColor: 'bg.surface',
          color: 'white'
        }
      }
    }
  }
}
