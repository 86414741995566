import { Dictionary } from '../Common/Types'

export function LoadDPRTestData(): Dictionary[] {
  return [
    {
      data: {
        cid: 124228591,
        name: 'Artillerist Rondo',
        checkedFeatures: {
          '1000002': true,
          '1038715': true
        },
        actionIdList: [
          {
            actionId: 1039130,
            level: 0
          },
          {
            actionId: 1411515020,
            level: 0
          }
        ],
        advantageOverrides: {
          '1039130100': 1
        },
        targetAC: 18,
        elvenAccuracy: false,
        dpr: '26.10'
      }
    },
    {
      data: {
        cid: 125159341,
        name: 'Weslocke',
        checkedFeatures: {
          '133': true,
          '138073': true,
          '1285992': true
        },
        actionIdList: [
          {
            actionId: 136704,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '55.80'
      }
    },
    {
      data: {
        cid: 123625627,
        name: 'Karlach',
        checkedFeatures: {
          '21': true,
          '52': true,
          '53': true,
          '66': true,
          '1009': true
        },
        actionIdList: [
          {
            actionId: 1404344619,
            level: 0
          },
          {
            actionId: 1404344618,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 15,
        elvenAccuracy: false,
        dpr: '39.44'
      }
    },
    {
      data: {
        cid: 120277643,
        name: 'Reya Greenleaf',
        checkedFeatures: {
          '66': true,
          '89': true,
          '166': true,
          '360': true,
          '1009': true,
          '1021': true,
          '53563': true,
          '53565': false,
          '71185': true,
          '1000361': true,
          '1479249': true,
          '2438252': true,
          '4034730': true,
          '1000000533782': true
        },
        actionIdList: [
          {
            actionId: 1402493829,
            level: 0
          },
          {
            actionId: 1402493829,
            level: 0
          },
          {
            actionId: 1402493799,
            level: 0
          },
          {
            actionId: 1402493799,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 20,
        elvenAccuracy: true,
        dpr: '176.77'
      }
    },
    {
      data: {
        cid: 123400549,
        name: 'Fake Bunny',
        checkedFeatures: {
          '3': true,
          '63': true,
          '360': true,
          '1021': true,
          '53484': true,
          '53563': true,
          '71185': true,
          '452845': true,
          '1000361': true,
          '2438252': true
        },
        actionIdList: [
          {
            actionId: 1401557148,
            level: 0
          },
          {
            actionId: 1401557148,
            level: 0
          },
          {
            actionId: 1401557148,
            level: 0
          },
          {
            actionId: 1401557148,
            level: 0
          },
          {
            actionId: 1401557148,
            level: 0
          },
          {
            actionId: 1401557148,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 5,
        elvenAccuracy: false,
        dpr: '206.88'
      }
    },
    {
      data: {
        cid: 123397217,
        name: 'Lae’zel',
        checkedFeatures: {
          '4': false,
          '21': true,
          '289': true,
          '1021': true,
          '53556': true,
          '53570': false,
          '1000003': true,
          '1000006': true
        },
        actionIdList: [
          {
            actionId: 1401520147,
            level: 0
          },
          {
            actionId: 1401520147,
            level: 0
          },
          {
            actionId: 1401520147,
            level: 0
          },
          {
            actionId: 1401520147,
            level: 0
          },
          {
            actionId: 1412571560,
            level: 0
          },
          {
            actionId: 1412571560,
            level: 0
          },
          {
            actionId: 1412571560,
            level: 0
          },
          {
            actionId: 1412571560,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '216.70'
      }
    },
    {
      data: {
        cid: 124067801,
        name: 'Flemin',
        checkedFeatures: {
          '53437': true,
          '1000001': true,
          '2437974': false,
          '100000024380033': true
        },
        actionIdList: [
          {
            actionId: 534393,
            level: 0
          },
          {
            actionId: 53437,
            level: 0
          },
          {
            actionId: 74185,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '62.64'
      }
    },
    {
      data: {
        cid: 124864988,
        name: 'Nameroc',
        checkedFeatures: {
          '1000001': true,
          '1000003': true
        },
        actionIdList: [
          {
            actionId: 18966222,
            level: 0
          },
          {
            actionId: 18966300,
            level: 0
          },
          {
            actionId: 138851,
            level: 1
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '37.80'
      }
    },
    {
      data: {
        cid: 124228925,
        name: 'Gale',
        checkedFeatures: {
          '53': true,
          '54': true,
          '55': true,
          '56': true,
          '57': true,
          '94': true,
          '1167': true,
          '157540': false,
          '1000001': false,
          '1000002': true,
          '1000006': true
        },
        actionIdList: [
          {
            actionId: 18868131,
            level: 0
          },
          {
            actionId: 136611,
            level: 9
          },
          {
            actionId: 209,
            level: 9
          },
          {
            actionId: 138955,
            level: 9
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '252.92'
      }
    },
    {
      data: {
        cid: 121696230,
        name: 'Barnaby',
        checkedFeatures: {
          '29': true,
          '219': true,
          '329': true,
          '655': true,
          '452845': true
        },
        actionIdList: [
          {
            actionId: 1435632227,
            level: 0
          },
          {
            actionId: 1435632503,
            level: 0
          },
          {
            actionId: 1435632227,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '42.25'
      }
    },
    {
      data: {
        cid: 124925524,
        name: 'Rogue',
        checkedFeatures: {
          '21': false,
          '94': true,
          '71185': true,
          '1000002': false,
          '2421851': true,
          '2438378': true
        },
        actionIdList: [
          {
            actionId: 2421823,
            level: 0
          },
          {
            actionId: 2421830,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '48.88'
      }
    },
    {
      data: {
        cid: 125098685,
        name: 'Sorc',
        checkedFeatures: {
          '1033': true,
          '53407': true,
          '2772255': true,
          '2772264': false,
          '2772302': true
        },
        actionIdList: [
          {
            actionId: 136168,
            level: 1
          },
          {
            actionId: 154574,
            level: 2
          },
          {
            actionId: 138953,
            level: 3
          },
          {
            actionId: 137861,
            level: 4
          },
          {
            actionId: 136815,
            level: 5
          },
          {
            actionId: 136610,
            level: 6
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '188.40'
      }
    },
    {
      data: {
        cid: 124309705,
        name: 'Shadowheart',
        checkedFeatures: {
          '53352': true,
          '53354': false,
          '2426185': true
        },
        actionIdList: [
          {
            actionId: 100000002,
            level: 0
          },
          {
            actionId: 138784,
            level: 9
          }
        ],
        advantageOverrides: {},
        targetAC: 6,
        elvenAccuracy: false,
        dpr: '64.40'
      }
    },
    {
      data: {
        cid: 123456864,
        name: 'Vadania Amakiir',
        checkedFeatures: {
          '42': true,
          '52': true,
          '63': true,
          '53392': true,
          '138101': true,
          '2421305': true,
          '2438252': true,
          '2438332': true
        },
        actionIdList: [
          {
            actionId: 1409454312,
            level: 0
          },
          {
            actionId: 1409454312,
            level: 0
          },
          {
            actionId: 18747880,
            level: 0
          },
          {
            actionId: 188525,
            level: 5
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '56.91'
      }
    },
    {
      data: {
        cid: 134957746,
        name: 'Dance Bard',
        checkedFeatures: {
          '1789160': false,
          '1789175': true,
          '9414247': true
        },
        actionIdList: [
          {
            actionId: 1541252473,
            level: 0
          },
          {
            actionId: 100000002,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 6,
        elvenAccuracy: false,
        dpr: '20.09'
      }
    },
    {
      data: {
        cid: 134105418,
        name: 'Soulknife 2024',
        checkedFeatures: {
          '9414058': true,
          '9414060': true,
          '9414607': true
        },
        actionIdList: [
          {
            actionId: 94146051000000,
            level: 0
          },
          {
            actionId: 9414605,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 21,
        elvenAccuracy: false,
        dpr: '51.90'
      }
    },
    {
      data: {
        cid: 134699880,
        name: '2024 Cleric',
        checkedFeatures: {
          '9414011': true,
          '9414184': true,
          '9414186': true
        },
        actionIdList: [
          {
            actionId: 9414008,
            level: 0
          },
          {
            actionId: 9414010,
            level: 0
          },
          {
            actionId: 21172152,
            level: 5
          },
          {
            actionId: 1537945629,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '81.40'
      }
    },
    {
      data: {
        cid: 134798275,
        name: '2024 Zealot Barb',
        checkedFeatures: {
          '1789149': true,
          '9413993': true,
          '9414000': true,
          '10292154': true,
          '10292412': true
        },
        actionIdList: [
          {
            actionId: 1539188280,
            level: 0
          },
          {
            actionId: 1539188280,
            level: 0
          },
          {
            actionId: 15391882809413978,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 15,
        elvenAccuracy: false,
        dpr: '93.78'
      }
    },
    {
      data: {
        cid: 134961040,
        name: '2024 Berserker Barb',
        checkedFeatures: {
          '1789121': true,
          '1789149': true,
          '1789183': true,
          '9413993': true,
          '9414000': true,
          '10292154': true,
          '10292400': true
        },
        actionIdList: [
          {
            actionId: 1541287613,
            level: 0
          },
          {
            actionId: 1541287613,
            level: 0
          },
          {
            actionId: 15412876139413978,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 15,
        elvenAccuracy: false,
        dpr: '101.36'
      }
    },
    {
      data: {
        cid: 134961196,
        name: '2024 Valor Bard',
        checkedFeatures: {
          '1789171': true,
          '1789175': true,
          '9414261': true
        },
        actionIdList: [
          {
            actionId: 1541290160,
            level: 0
          },
          {
            actionId: 5477211,
            level: 9
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '89.30'
      }
    },
    {
      data: {
        cid: 128408279,
        name: 'D4 Ep 30: The Needler',
        checkedFeatures: {
          '1': true,
          '42': true,
          '89': true,
          '1021': true,
          '53484': true,
          '138323': true,
          '1306493': true,
          '2439094': true
        },
        actionIdList: [
          {
            actionId: 1462216755,
            level: 0
          },
          {
            actionId: 1462216755,
            level: 0
          },
          {
            actionId: 1462216755,
            level: 0
          },
          {
            actionId: 1462216755,
            level: 0
          },
          {
            actionId: 1462216755,
            level: 0
          },
          {
            actionId: 1462216755,
            level: 0
          },
          {
            actionId: 1462216755,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: true,
        dpr: '132.95'
      }
    },
    {
      data: {
        cid: 126669113,
        name: 'D4 171: The Baseline',
        checkedFeatures: {
          '21': true,
          '53': true,
          '59': true,
          '1009': true,
          '1000004': true,
          '1000005': true,
          '1000006': true,
          '1285990': true,
          '7508783': true
        },
        actionIdList: [
          {
            actionId: 1440962963,
            level: 0
          },
          {
            actionId: 1440962963,
            level: 0
          },
          {
            actionId: 1440962963,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '121.28'
      }
    },
    {
      data: {
        cid: 126667382,
        name: 'DnDShorts Hexblade',
        checkedFeatures: {
          '21': false,
          '29': false,
          '89': true,
          '133': true,
          '148': true,
          '204': true,
          '1131': true,
          '130379': false,
          '138073': false,
          '1000001': true,
          '1000002': false,
          '5833698': true
        },
        actionIdList: [
          {
            actionId: 136704,
            level: 0
          },
          {
            actionId: 136704,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: true,
        dpr: '235.44'
      }
    },
    {
      data: {
        cid: 126026387,
        name: 'D4 170: Crit Fisher 2.0',
        checkedFeatures: {
          '89': true,
          '166': true,
          '56245': true,
          '71185': true,
          '1307829': true,
          '1000000533782': true
        },
        actionIdList: [
          {
            actionId: 1433185594,
            level: 0
          },
          {
            actionId: 1433185594,
            level: 0
          },
          {
            actionId: 19147774,
            level: 0
          },
          {
            actionId: 19147774,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 13,
        elvenAccuracy: true,
        dpr: '80.32'
      }
    },
    {
      data: {
        cid: 135171252,
        name: 'Grappla',
        checkedFeatures: {
          '89': true,
          '1789147': true,
          '1789202': true,
          '9414000': true,
          '9414669': true
        },
        actionIdList: [
          {
            actionId: 100000001,
            level: 0
          },
          {
            actionId: 1543804285,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 12,
        elvenAccuracy: true,
        dpr: '17.17'
      }
    },
    {
      data: {
        cid: 135406338,
        name: '2024 Elemental Glam Bard',
        checkedFeatures: {
          '1000007': true,
          '1789133': true,
          '1789134': true
        },
        actionIdList: [
          {
            actionId: 1278189,
            level: 0
          },
          {
            actionId: 1278192,
            level: 9
          },
          {
            actionId: 1278191,
            level: 9
          },
          {
            actionId: 1266076,
            level: 0
          },
          {
            actionId: 1278254,
            level: 0
          },
          {
            actionId: 1278151,
            level: 5
          }
        ],
        advantageOverrides: {},
        targetAC: 11,
        elvenAccuracy: false,
        dpr: '164.93'
      }
    },
    {
      data: {
        cid: 135434579,
        name: 'Fighty McStyles',
        checkedFeatures: {
          '1000002': true,
          '1789102': true,
          '1789131': false,
          '1789148': true,
          '1789175': false,
          '1789205': true,
          '1789207': true,
          '9414392': false,
          '9414669': true
        },
        actionIdList: [
          {
            actionId: 1547188457,
            level: 0
          },
          {
            actionId: 1547188446,
            level: 0
          },
          {
            actionId: 1547188457,
            level: 0
          },
          {
            actionId: 1547188448,
            level: 0
          },
          {
            actionId: 1547207878,
            level: 0
          },
          {
            actionId: 1547207457,
            level: 0
          },
          {
            actionId: 1547188607,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 17,
        elvenAccuracy: false,
        dpr: '64.05'
      }
    },
    {
      data: {
        cid: 135637098,
        name: '2024 Sea Druid',
        checkedFeatures: {
          '1789133': true,
          '1789160': true,
          '1789202': true,
          '4496851': true
        },
        actionIdList: [
          {
            actionId: 20540271,
            level: 0
          },
          {
            actionId: 9414274,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 13,
        elvenAccuracy: false,
        dpr: '30.80'
      }
    },
    {
      data: {
        cid: 132888808,
        name: 'Thoronn 2024',
        checkedFeatures: {
          '9414069': true,
          '9414084': false,
          '9414671': true,
          '10292362': true,
          '21172828': false,
          '21173226': true,
          '22173227': false
        },
        actionIdList: [
          {
            actionId: 21170739,
            level: 0
          },
          {
            actionId: 1525447536,
            level: 0
          },
          {
            actionId: 1525447536,
            level: 0
          },
          {
            actionId: 1525447536,
            level: 0
          },
          {
            actionId: 21170996,
            level: 3
          }
        ],
        advantageOverrides: {},
        targetAC: 17,
        elvenAccuracy: false,
        dpr: '57.61'
      }
    },
    {
      data: {
        cid: 136277036,
        name: '2024 Light Cleric',
        checkedFeatures: {
          '9414169': true
        },
        actionIdList: [
          {
            actionId: 9414166,
            level: 0
          },
          {
            actionId: 21171352,
            level: 1
          },
          {
            actionId: 21171674,
            level: 2
          },
          {
            actionId: 21170999,
            level: 3
          },
          {
            actionId: 21172290,
            level: 4
          },
          {
            actionId: 21171195,
            level: 5
          },
          {
            actionId: 1557909906,
            level: 0
          },
          {
            actionId: 9414008,
            level: 0
          },
          {
            actionId: 9414010,
            level: 0
          }
        ],
        advantageOverrides: {},
        targetAC: 16,
        elvenAccuracy: false,
        dpr: '132.89'
      }
    },
    {
      data: {
        cid: 136298136,
        name: '2024 Moon Druid',
        checkedFeatures: {
          '1789202': true,
          '4496851': true,
          '9414270': true,
          '9414273': true
        },
        actionIdList: [
          {
            actionId: 20635425,
            level: 0
          },
          {
            actionId: 20635426,
            level: 0
          }
        ],
        advantageOverrides: {
          '20635626': 1
        },
        targetAC: 15,
        elvenAccuracy: false,
        dpr: '76.36'
      }
    },
    {
      data: {
        cid: 136301419,
        name: '2024 Evoker Wiz',
        checkedFeatures: {
          '9414653': true,
          '10292990': true
        },
        actionIdList: [
          {
            actionId: 21170997,
            level: 5
          }
        ],
        advantageOverrides: {},
        targetAC: 10,
        elvenAccuracy: false,
        dpr: '59.20'
      }
    }
  ]
}
