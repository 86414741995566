import { Dictionary } from '../Common/Types'

export class WeaponMastery {
  name: string // Cleave
  weaponType: string // Greataxe

  constructor(feat: Dictionary) {
    const [name, weapon] = feat.subType.split('-').map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
    this.name = name
    this.weaponType = weapon
  }
}
