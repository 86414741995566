import { Activation } from '../DDB/Activation'
import { Dice, DiceCollection } from './Dice'
import { SPELL } from '../Common/Constants'
import { Utility } from '../Common/Utility'
import { AttackAction } from '../DDB/AttackAction'
import { Character } from './Character'
import { Dictionary } from '../Common/Types'

// TODO: This still takes character as a parameter, it would be nice to decouple that out somehow or use some interface

export class Spell {
  id: number
  name: string // the DDB name before it's overriden
  displayName: string
  level: number
  attackType: number | null = null // 1: Melee/touch, 2: Ranged
  activation?: Activation
  damageTypes: string[] | null = null
  countsAsKnownSpell: boolean = false
  requiresSavingThrow: boolean
  requiresAttackRoll: boolean | null = null
  addPrimaryStat: boolean | null = null
  damageMod: number | null = null
  attackMod: number = 0
  character: Character | null = null
  requiresConcentration: boolean
  ritual: boolean = false
  saveDcAbilityId: number | null = null
  saveDc: number | null = null
  range: Range | null = null
  school: string | null = null
  aoeType: string | null = null
  aoeValue: number | null = null
  isPrepared: boolean
  definitionId: number | null = null
  ddbURL: string | null = null

  // Scaling stuff
  scaleType: string | null = null
  effectCount: number = 0
  dice: Dice | null = null
  higherLevelDice: Dice | null = null
  additionalDamageDice: Dice | null = null
  higherLevelIncrement: number = 0 // How many spell slots levels are needed before higherLevel are added
  effectCountLabel: string | null = null // Beams, Darts, Creatures, etc

  constructor(spellData: Dictionary, character: Character) {
    this.character = character // TODO… Stop storing character in here?
    this.id = spellData.id

    const definition = spellData.definition
    if (!definition) {
      this.name = ''
      this.displayName = ''
      this.level = 0
      this.requiresSavingThrow = false
      this.requiresConcentration = false
      this.isPrepared = false
      //console.error('No definition for spell ', spellData)
      return
    }

    const { name, level, school, concentration, requiresSavingThrow, range, ritual, modifiers, scaleType } = definition

    this.definitionId = definition.id
    this.name = name
    this.displayName = name
    this.level = level
    this.school = school
    this.requiresConcentration = concentration
    this.requiresSavingThrow = requiresSavingThrow
    this.range = range
    this.ritual = ritual
    this.scaleType = scaleType
    const additionalDamageDieBlob = modifiers.find((m: Dictionary) => m.type === 'damage' && m.subType === 'additional')
    if (additionalDamageDieBlob !== undefined) {
      // Example Chaos Bolt is 2d8+1d6 at base level
      // TODO - this only applies if there is higher level damage dice. Are there spells that don't meet that?
      this.additionalDamageDice = new Dice(additionalDamageDieBlob.die)
    }

    if (definition.saveDcAbilityId != null) {
      this.saveDcAbilityId = parseInt(definition.saveDcAbilityId) - 1
      this.saveDc = character.spellSaveDC
    }

    this.countsAsKnownSpell = spellData.countsAsKnownSpell
    this.attackMod = character.spellAttackModifier
    this.isPrepared = true // spellData.prepared || spellData.alwaysPrepared

    for (const modifier of modifiers) {
      const type = modifier.type
      if (type !== 'damage') {
        continue
      }

      const { attackType, requiresAttackRoll, activation } = definition

      this.attackType = attackType
      this.requiresAttackRoll = requiresAttackRoll
      this.activation = new Activation(activation)
      if (this.name === 'Magic Stone') {
        this.activation = Activation.Action()
      }

      const { subType, die, usePrimaryStat } = modifier
      if (subType) {
        if (!this.damageTypes) {
          this.damageTypes = []
        }

        if (subType !== 'additional') {
          this.damageTypes.push(subType)
        }
      }

      this.dice = this.calculateDice(die)
      const characterLevel = character.totalLevel
      this.calculateInitialSpellDetails(modifiers, characterLevel) // Do this before higher level dice
      this.calculateHigherLeveLDice(definition.atHigherLevels, modifiers, characterLevel)

      this.addPrimaryStat = usePrimaryStat
    }

    if (this.definitionId) {
      const spellSuffix = this.name.replace(/ /g, '-').replace(/'/g, '').toLowerCase()
      this.ddbURL = `https://www.dndbeyond.com/spells/${this.definitionId}-${spellSuffix}`
    }
  }

  scalesWithSpellLevel() {
    return this.scaleType && this.scaleType === 'spellscale'
  }

  scalesWithCharacterLevel() {
    return this.scaleType && this.scaleType === 'characterlevel'
  }

  attackAction() {
    return new AttackAction(
      this.id,
      this.displayName,
      this.attackMod,
      this.damageDice(),
      this.spellAttributes(),
      this.activation!
    )
  }

  // lol at what point is this the entire class
  spellAttributes() {
    let activationTypeString = 'Unknown'
    if (this.activation) {
      activationTypeString = this.activation.activationTypeString()
    }

    const effectCountsForLevels = []
    const diceCollectionsForLevels: DiceCollection[] = []

    if (this.higherLevelIncrement > 0) {
      for (let level = 1; level <= 9; level++) {
        const count = this.effectCount + this.higherLevelIncrement * Math.max(0, level - this.level)
        effectCountsForLevels.push(count)
      }
    }

    if (this.higherLevelDice) {
      const dice = this.damageDice()

      if (!dice) {
        console.error('No dice for upcast spell ' + this.name)
      }

      for (let level = 1; level <= 9; level++) {
        const N = Math.max(level - this.level, 0)
        const value = this.higherLevelDice.copy()
        const upscaledDice = new Array(N).fill(value)
        const diceCollection = new DiceCollection().addDiceList([dice, upscaledDice].flat())
        if (this.additionalDamageDice !== null) {
          diceCollection.addDice(this.additionalDamageDice)
        }

        diceCollectionsForLevels.push(diceCollection)
      }
    }
    const isCantrip = this.level === 0

    return {
      id: this.id,
      definitionId: this.definitionId,
      ddbURL: this.ddbURL,
      name: this.name,
      displayName: this.displayName,
      type: SPELL,
      level: this.level,
      isCantrip: isCantrip,
      damageTypes: this.damageTypes,
      requiresAttackRoll: this.requiresAttackRoll,
      requiresConcentration: this.requiresConcentration,
      requiresSavingThrow: this.requiresSavingThrow,
      effectCount: this.effectCount,
      effectCountsForLevels: effectCountsForLevels,
      effectCountLabel: this.effectCountLabel,
      diceCollectionsForLevels: diceCollectionsForLevels,
      attackMod: this.attackMod,
      ritual: this.ritual,
      range: this.range,
      saveDcAbility: Utility.shortNameForAbilityID(this.saveDcAbilityId),
      saveDcValue: this.saveDc,
      activation: activationTypeString,
      school: this.school,
      dealsDamage: true
    }
  }

  calculateDice(die: Dice | null) {
    if (!die) {
      return null
    }

    const newDice = new Dice(die)
    if (this.dice === null) {
      return newDice
    }

    if (this.dice.isGreaterThan(newDice)) {
      return this.dice
    }

    return newDice
  }

  calculateInitialSpellDetails(modifiers: Dictionary, characterLevel: number) {
    const name = this.name
    if (name === 'Eldritch Blast') {
      this.effectCountLabel = 'Beams'
      this.effectCount = 1 + Math.floor((characterLevel + 1) / 6)
    } else if (name === 'Magic Missile') {
      this.effectCount = 3
      this.effectCountLabel = 'Darts'
    } else if (name === "Tasha's Mind Whip") {
      this.effectCountLabel = 'Creatures'
      this.effectCount = 1
    } else if (name === 'Hex' || name === "Hunter's Mark") {
      this.effectCountLabel = 'Concentration'
      this.effectCount = 1
    } else if (name === 'Scorching Ray') {
      this.effectCountLabel = 'Rays'
      this.effectCount = 3
    } else if (name === 'Chain Lightning') {
      this.effectCountLabel = 'Targets'
      this.effectCount = 3
    } else if (name === 'Flame Strike') {
      this.effectCount = 1
      this.effectCountLabel = 'Strike' // The spell doesn't have beams, but it does have optional damage types.
    }
  }

  calculateHigherLeveLDice(atHigherLevels: Dictionary, modifiers: Dictionary[], characterLevel: number) {
    if (atHigherLevels.higherLevelDefinitions.length > 0) {
      const { typeId, value, details } = atHigherLevels.higherLevelDefinitions[0]

      this.higherLevelIncrement = value

      if (!this.effectCountLabel) {
        console.warn(`DEBUGGING: New spell with new beam name ${this.name}, details = ${details}, typeId = ${typeId}`)
      }
    }

    for (const modifier of modifiers) {
      const atHigherLevels = modifier.atHigherLevels
      if (atHigherLevels) {
        if (this.scalesWithCharacterLevel()) {
          // Cantrips & such
          const definitions: Dictionary[] = atHigherLevels.higherLevelDefinitions
          for (const spellDefinition of definitions) {
            const spellLevel = spellDefinition.level
            if (spellLevel <= characterLevel) {
              this.dice = this.calculateDice(spellDefinition.dice)
            }
          }
        } else if (this.scalesWithSpellLevel()) {
          const definitions: Dictionary[] = atHigherLevels.higherLevelDefinitions
          if (definitions.length === 0) {
            // Do nothing, it's probably handled in the base atHigherLevels
          } else if (definitions.length === 1) {
            const definition = definitions[0]
            const dice = definition.dice
            const value = definition.value
            if (dice) {
              this.higherLevelDice = new Dice(dice)
              //this.higherLevelIncrement = definition.level // TODO - Spiritual Weapon has this at 2
            } else if (value) {
              this.higherLevelDice = Dice.flatAmountDie(value)
            } else {
              console.warn('No dice for higher level spell ' + this.name)
              console.log(definition)
            }
          } else {
            if (this.name !== 'Arms of Hadar') {
              console.error('Multiple higher level definitions for spell?' + this.name)
              console.error(definitions)
            }
          }
        }
      }
    }
  }

  attackString(): string {
    return this.damageStringForCharacter(this.character!)
  }

  damageDice(): Dice {
    return this.damageDiceForCharacter(this.character!)
  }

  damageStringForCharacter(character: Character): string {
    if (!this.dice) {
      return '0'
    }

    let damageString = this.dice.diceString()

    if (this.addPrimaryStat) {
      this.damageMod = this.spellDamageBonusForCharacter(character)
      damageString += '+' + this.damageMod.toString()
    } else {
      this.damageMod = 0
    }

    if (damageString === null) {
      return '0'
    }

    return damageString
  }

  damageDiceForCharacter(character: Character): Dice {
    if (!this.dice) {
      console.error('Trying to get damage dice for spell ' + this.name + ' but there are none.')
      return Dice.flatAmountDie(0)
    }

    const damageDice: Dice = this.dice.copy()

    if (this.addPrimaryStat) {
      // TODO… why is this assigning? Is that safe? Investigate.
      this.damageMod = this.spellDamageBonusForCharacter(character)
      damageDice.fixedValue += this.damageMod
    }

    return damageDice
  }

  spellDamageBonusForCharacter(character: Character): number {
    if (this.addPrimaryStat) {
      return character.spellcastingAbilityModifier
    }
    return 0
  }
}
