import { Dictionary } from '../Common/Types'

export class CharacterClass {
  className: string
  subclassName: string | null = null
  subclassId: number | null = null
  pactName?: string | null = null // for Warlock
  level: number
  spellCastingAbility: number | null = null
  levelSpellSlots: number[] = []

  constructor(classData: Dictionary, classOptions: Dictionary[]) {
    const { definition, subclassDefinition, level } = classData
    const { spellRules, name, spellCastingAbilityId } = definition

    this.level = level
    this.className = name

    if (this.className === 'Warlock') {
      const classOption = classOptions.find((option) => option.definition.name.startsWith('Pact of the'))
      if (classOption) {
        this.pactName = classOption.definition.name
      }
    }

    if (subclassDefinition !== null && 'name' in subclassDefinition) {
      this.subclassName = subclassDefinition.name
      this.subclassId = subclassDefinition.id
    }

    if (spellCastingAbilityId !== null) {
      this.spellCastingAbility = spellCastingAbilityId - 1
    }

    if (spellRules && spellRules.levelSpellSlots !== null) {
      this.levelSpellSlots = spellRules.levelSpellSlots[this.level]
    }
  }

  classDisplayString(): string {
    return `${this.className} ${this.subclassName ? ` (${this.subclassName}${this.pactName ? `, ${this.pactName}` : ''})` : ''}`
  }

  highestLevelSpellSlot(): number {
    for (let i = this.levelSpellSlots.length - 1; i >= 0; i--) {
      if (this.levelSpellSlots[i] !== 0) {
        return i + 1
      }
    }
    return 0
  }
}
