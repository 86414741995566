import { Dice } from './Dice'
import { Weapon } from './Weapon'
import { Utility } from '../Common/Utility'
import { Activation } from '../DDB/Activation'
import { AttackAction } from '../DDB/AttackAction'
import { Character } from './Character'
import { Dictionary } from '../Common//Types'

// This is meant to be dumb object, easily serializable into json
// Hopefully we can merge this and Attack Action later?
export class Attack {
  id: number
  attributes: Dictionary
  displayName: string
  weaponType: string
  attackMod: number
  damageMod: number
  magicBonus: number

  dice: Dice
  attackString: string

  constructor(weapon: Weapon, character: Character) {
    this.id = weapon.id
    this.weaponType = weapon.name

    this.displayName = weapon.name
    if (weapon.customizedName !== null) {
      this.displayName = weapon.customizedName
    }

    this.magicBonus = weapon.magicBonus
    const pb = character.proficiencyBonus
    const abilityScore = character.abilityScoreForIndex(weapon.attackStatIndex)
    const statModifier = Utility.modifierForScore(abilityScore)

    this.damageMod = weapon.attackMod + this.magicBonus + statModifier
    this.attackMod = weapon.attackMod + this.magicBonus + statModifier + pb
    this.attributes = weapon.weaponAttributes()
    this.dice = weapon.baseDice.copy()

    if (!this.attributes.dealsDamage) {
      this.damageMod = 0
    }

    this.attackString = Attack.damageString(this.dice.diceString(), this.damageMod)
  }

  attackAction(offhand: boolean = false) {
    const activation = offhand ? Activation.BonusAction() : Activation.Action()
    const damageDice = this.dice.copy()
    if (!offhand || this.damageMod < 0) {
      damageDice.fixedValue += this.damageMod
    } else {
      damageDice.fixedValue += this.magicBonus
    }

    return new AttackAction(this.id, this.displayName, this.attackMod, damageDice, this.attributes, activation)
  }

  static damageString(diceString: string, damageMod: number) {
    let damageString = diceString
    if (damageMod > 0) {
      damageString += '+'
    } else if (damageMod < 0) {
      damageString += damageMod.toString()
    }

    return damageString
  }
}
