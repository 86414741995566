import { Activation } from '../DDB/Activation'
import { Dice } from '../DDB/Dice'
import { Dictionary } from '../Common/Types'

export interface AttackActionInterface {
  id: number
  name: string
  attackMod: number
  activation: Activation
  attributes: Dictionary
  attackModString(increase?: number): string
  saveDCString(): string
  dice: Dice
  turnLevel: number

  diceString(): string
  diceStringForLevel(level: number): string

  copy(): AttackActionInterface
}

export interface ActionLevelMap {
  actionId: number
  level: number
}

export interface DamageData {
  average: number
  total: number
  expression: string
  percentiles: { [key: number]: number }
}

export class NumberMap {
  [key: number]: number

  length(): number {
    return Object.keys(this).length
  }

  copy(): NumberMap {
    const newMap = new NumberMap()
    for (const key in this) {
      newMap[key] = this[key]
    }
    return newMap
  }
}

export interface CheckMap {
  [key: number]: boolean
}

export interface DictMap {
  [key: number]: Dictionary
}

export interface SpellSource {
  spells: Dictionary[]
  forceKnown: boolean
}
