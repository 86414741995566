import { ABILITIES } from '../Common/Constants'
import { Dictionary } from '../Common/Types'

export class Feat {
  name: string
  type: string
  subtype: string
  id: number
  description: string
  attrIndex: number | null = null
  attrValue: number = 0
  optionDefinition: Dictionary = {}
  isFightingStyle: boolean = false

  constructor(featData: Dictionary, modifiersData: Dictionary[], featOptionsData: Dictionary[]) {
    const featDefinitionData = featData.definition
    this.name = featDefinitionData.name
    this.id = featDefinitionData.id
    this.type = featDefinitionData.type
    this.subtype = featDefinitionData.subType
    this.description = featDefinitionData.description

    // 2024 has new feat option choices, like Elemental Adept
    const matchingFeatOption = featOptionsData.find((option) => option.componentId === this.id)
    if (matchingFeatOption) {
      this.optionDefinition = matchingFeatOption.definition
    }

    // Find if this feat granted attribute points
    for (const modifierData of modifiersData) {
      const componentId = modifierData.componentId
      const type = modifierData.type

      if (type === 'bonus' && componentId === this.id) {
        const subtype = modifierData.subType
        const attrName = subtype.slice(0, -'-score'.length)
        this.attrIndex = ABILITIES.indexOf(attrName)
        this.attrValue = parseInt(modifierData.value)
      }
    }

    this.isFightingStyle = featDefinitionData.categories.some(
      (category: Dictionary) => category.tagName === 'Fighting Style'
    )
  }
}
