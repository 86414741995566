import { URLUtility } from './Utility'
import { NavigateFunction } from 'react-router'

export function handleCharacterButtonClick(navigate: NavigateFunction, urlFieldValue: string) {
  const url = URLUtility.urlFromFieldValue(urlFieldValue)
  if (url) {
    const parts = url.pathname.split('/')
    const characterIndex = parts.indexOf('characters')
    const characterId = parts[characterIndex + 1]
    const cid = parseInt(characterId)
    // TODO - be smarter about if there are other things in the URL
    if (cid) {
      navigate(`/character/${cid}`)
    }
  }
}
