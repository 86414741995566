import { AttackAction } from './AttackAction'
import { Campaign } from './Campaign'
import { Feature } from './Feature'
import { CheckMap } from '../Common/Interfaces'
import { Character } from './Character'
import { TurnAction } from './TurnAction'
import { Dice, DiceCollection } from './Dice'
import { Spell } from './Spell'
import { Activation } from './Activation'
import { FightingStyle } from './FightingStyle'
import { Utility } from '../Common/Utility'
import { Dictionary } from '../Common/Types'

export class CharacterInfo {
  id: number
  name: string
  race: string
  url: string
  totalLevel: number
  proficiencyBonus: number
  classNames: [string, number][]
  analyticsClassNames: Dictionary[]
  featNames: string[]
  fightingStyles: FightingStyle[]
  attacks: AttackAction[]
  bonusAttacks: AttackAction[]
  avatarUrl: string
  spells: AttackAction[]
  abilityScores: number[]
  allFeatures: Feature[]
  campaign: Campaign
  spellcastingAbilityModifier: number

  attackCount: number
  fixedSpellLevel: number
  highestLevelSpellSlot: number
  defaultFeatures: CheckMap = {}

  prevState: null | null = null

  constructor(character: Character) {
    this.name = character.name
    this.id = character.id
    this.url = 'https://www.dndbeyond.com/characters/' + character.id
    this.race = character.race
    this.proficiencyBonus = character.proficiencyBonus
    this.spellcastingAbilityModifier = character.spellcastingAbilityModifier
    this.totalLevel = character.totalLevel
    this.classNames = character.classNames()
    this.featNames = character.featNames()
    this.fightingStyles = character.fightingStyles
    this.attacks = character.attackActions
    this.bonusAttacks = character.attackBonusActions()
    this.avatarUrl = character.avatarUrl
    this.spells = character.damagingSpellActions()
    this.abilityScores = character.abilityScores
    this.allFeatures = character.features
    this.attackCount = character.attackCount
    this.fixedSpellLevel = character.warlockSpellLevel()
    this.highestLevelSpellSlot = character.highestLevelSpellSlot
    this.defaultFeatures = character.defaultEnabledFeatureMap()
    this.campaign = character.campaign
    this.analyticsClassNames = character.classAnalyticsNames()
  }

  modifierForAbilityIndex(attrIndex: number): number {
    const score = this.abilityScores![attrIndex]
    return Utility.modifierForScore(score)
  }

  totalDamageStringForTurns(turnActions: TurnAction[]): string {
    if (!turnActions) {
      return ''
    }

    return this.totalDamageForTurns(turnActions).displayString()
  }

  totalDamageForTurns(turnActions: TurnAction[]): DiceCollection {
    const allDiceCollection = new DiceCollection()
    for (const turnAction of turnActions) {
      const dice = turnAction.autoCrit
        ? turnAction.critDiceCollectionForLevel(turnAction.attackAction?.turnLevel || 0)
        : turnAction.allDamageDiceCollection(turnAction.attackAction?.turnLevel || 0)
      allDiceCollection.addDiceCollection(dice)
    }
    return allDiceCollection
  }

  totalCritDiceStringForTurns(turnActions: TurnAction[]): string {
    if (!turnActions) {
      return ''
    }

    return this.totalCritDiceForTurns(turnActions).displayString()
  }

  totalCritDiceForTurns(turnActions: TurnAction[]): DiceCollection {
    const allDiceCollection = new DiceCollection()
    for (const turnAction of turnActions) {
      const dice = turnAction.critDiceCollectionForLevel(turnAction.attackAction?.turnLevel || 0)
      allDiceCollection.addDiceCollection(dice)
    }
    return allDiceCollection
  }

  applyFeaturesToTurnActions(features: Feature[], turnActions: TurnAction[]) {
    if (turnActions.length === 0) {
      return
    }

    const elvenAccuracy = features.some((thisFeature) => thisFeature.rerollOnAdvantage)

    // This is a tricky case - if we have grappler AND the first attack is unarmed, then we apply advantage on subsequent melee attacks
    const nextAttacksHaeAdvantage = features.find((feature) => feature.nextAttacksHaveAdvantage)
    if (nextAttacksHaeAdvantage) {
      const grappler = features.find((feature) => feature.id === 1789147)
      if (grappler) {
        let foundUnarmed = false

        for (let i = 0; i < turnActions.length; i++) {
          if (!foundUnarmed && turnActions[i].isUnarmed()) {
            foundUnarmed = true
            continue
          }

          if (foundUnarmed) {
            const turn = turnActions[i]
            if (turn.isMeleeAttack()) {
              turn.advantage = elvenAccuracy ? 2 : 1
            }
          }
        }
      }
    }

    const scores = this.abilityScores

    const bb = features.find((feature) => feature.isBoomingBlade)
    if (bb) {
      bb.targetMoved = features.some((feature) => feature.isBoomingBladeTargetMoved)
    }

    for (const feature of features) {
      const {
        oncePerTurn,
        cantripsOrWeaponsOnly,
        cantripOnly,
        potentCantrip,
        rangedWeaponOnly,
        unarmedOnly,
        flurryOfBlowsOnly,
        radiantSunBoltOnly,
        astralArmsOnly,
        finessOrRangedeWeaponOnly,
        bowOnly,
        thrownWeaponOnly,
        meleeWeaponOnly,
        meleeAttackOnly,
        heavyWeaponOnly,
        finesseWeaponOnly,
        hornsOnly,
        twoHandedOrVersatileOnly,
        singleWieldingOnly,
        offHandOnly,
        weaponOnly,
        additionalMeleeRange,
        alsoAppliesToCompanionAttacks,
        onlyAppliesToCompanionAttacks,
        spellOnly,
        evocationSpellsOnly,
        fireDamageOnly,
        lightningOrThunderDamageOnly,
        singleTargetSpellOnly,
        tollTheDeadOnly,
        spellAttackOnly,
        attackRollOnly,
        pactWeaponOnly,
        eldritchBlastOnly,
        fireOrRadiantDamageOnly,
        wildshapeAttackOnly,
        piercingDamageOnly,
        elementalAdeptDamageType,
        psychicBladeOnly,
        recklessAttackOnly,
        lightWeaponOnly,
        ragingOnly
      } = feature

      const addFeature = (featureCode: (turn: TurnAction) => void) => {
        for (const turn of turnActions) {
          if (!alsoAppliesToCompanionAttacks && !onlyAppliesToCompanionAttacks && turn.isCompanion()) continue
          if (weaponOnly && !turn.isWeapon()) continue
          if (unarmedOnly && !turn.isUnarmed()) continue
          if (bowOnly && !turn.isBow()) continue
          if (finessOrRangedeWeaponOnly && !(turn.isFinesseWeapon() || turn.isRangedWeapon())) continue
          if (rangedWeaponOnly && !turn.isRangedWeapon()) continue
          if (meleeWeaponOnly && !turn.isMeleeWeapon()) continue
          if (lightWeaponOnly && !turn.isLightWeapon()) continue
          if (meleeAttackOnly && !turn.isMeleeAttack()) continue
          if (cantripsOrWeaponsOnly && !(turn.isWeapon() || turn.isCantrip())) continue
          if (cantripOnly && !turn.isCantrip()) continue
          if (potentCantrip && !turn.isCantrip()) continue
          if (feature.maxSpellLevel > 0 && (turn.isCantrip() || turn.spellLevel() > feature.maxSpellLevel)) continue
          if (finesseWeaponOnly && !turn.isFinesseWeapon()) continue
          if (hornsOnly && !turn.isHorns()) continue
          if (heavyWeaponOnly && !turn.isHeavyWeapon()) continue
          if (spellAttackOnly && !turn.isSpellAttack()) continue
          if (attackRollOnly && !turn.isAttackRoll()) continue
          if (twoHandedOrVersatileOnly && !(turn.isVersatileWeapon() || turn.isTwoHandedWeapon())) continue
          if (singleWieldingOnly && (turn.isTwoHandedWeapon() || turnActions.some((t) => t.isOffHand()))) continue
          if (offHandOnly && !turn.attackAttributes().isOffHand) continue
          if (spellOnly && !turn.isSpell()) continue
          if (evocationSpellsOnly && !turn.isEvocationSpell()) continue
          if (fireDamageOnly && !turn.isFireDamage()) continue
          if (fireOrRadiantDamageOnly && !(turn.isFireDamage() || turn.isRadiantDamage())) continue
          if (piercingDamageOnly && !turn.isPiercingDamage()) continue
          if (lightningOrThunderDamageOnly && !(turn.isThunderDamage() || turn.isLightningDamage())) continue
          if (pactWeaponOnly && !turn.isPactWeapon()) continue
          if (eldritchBlastOnly && !turn.isEldritchBlast()) continue
          if (singleTargetSpellOnly && !turn.isSingleTargetSpell()) continue
          if (tollTheDeadOnly && !turn.isTollTheDead()) continue
          if (thrownWeaponOnly && !turn.isThrownWeapon()) continue
          if (additionalMeleeRange && !turn.isMeleeWeapon()) continue
          if (onlyAppliesToCompanionAttacks && !turn.isCompanion()) continue
          if (flurryOfBlowsOnly && !turn.isFlurryOfBlows()) continue
          if (radiantSunBoltOnly && !turn.isRadiantSunBolt()) continue
          if (psychicBladeOnly && !turn.isPsychicBlades()) continue
          if (astralArmsOnly && !turn.isAstralArms()) continue
          if (wildshapeAttackOnly && !turn.isWildShapeAttack()) continue
          if (elementalAdeptDamageType && !turn.isDamageType(elementalAdeptDamageType)) continue
          if (recklessAttackOnly && !features.some((feature) => feature.name === 'Reckless Attack')) continue
          if (ragingOnly && !features.some((feature) => feature.raging)) continue

          featureCode(turn)

          if (oncePerTurn) break
        }
      }

      if (feature.nextAttacksHaveAdvantage) {
        for (let i = 1; i < turnActions.length; i++) {
          const turn = turnActions[i]
          if (turn.isAttackRoll()) {
            turn.advantage = elvenAccuracy ? 2 : 1
          }
        }
      }

      if (feature.damageVulnerability) {
        addFeature((turn) => (turn.damageVulnerability = feature.damageVulnerability))
      }

      if (feature.additionalToHitDice) {
        addFeature((turn) => turn.bonusToHitDice.push(feature.additionalToHitDice!))
      }

      if (feature.critDiceDealMaxDamage) {
        addFeature((turn) => (turn.critDiceDealMaxDamage = feature.critDiceDealMaxDamage))
      }

      if (feature.isTrueStrike) {
        const spellCastingModifier = this.spellcastingAbilityModifier
        addFeature(function addWeaponStatModifier(turn: TurnAction): void {
          const attrs = turn.attackAttributes()
          const abilityIndex = attrs.attackStatIndex

          const score = scores[abilityIndex]
          const statModifier = Utility.modifierForScore(score)

          if (abilityIndex !== undefined) {
            turn.bonusDamageDiceCollection.modifier -= statModifier
            turn.bonusDamageDiceCollection.modifier += spellCastingModifier

            turn.bonusToHitDice.push(Dice.flatAmountDie(-statModifier))
            turn.bonusToHitDice.push(Dice.flatAmountDie(spellCastingModifier))
          } else {
            console.error('No attack stat index found for weapon')
          }
        })
      }

      if (feature.additionalDamageOnHitDice) {
        const diceCollection = new DiceCollection().addDice(feature.additionalDamageOnHitDice)

        if (feature.targetMoved && feature.additionalDamageOnMoveDice) {
          diceCollection.addDice(feature.additionalDamageOnMoveDice)
        }

        if (feature.onlyOneBeam) {
          addFeature((turn) => turn.singleEffectBonusDamageDiceCollection.addDiceCollection(diceCollection))
        } else {
          addFeature((turn) => turn.bonusDamageDiceCollection.addDiceCollection(diceCollection))
        }
      }

      if (feature.extraAttackFirstRoundDice) {
        const validTurnActions = [
          ...turnActions.filter(
            (action) => action.attackAction !== undefined && action.attackAction.activation.usesAction()
          )
        ]

        // TODO if it's this.extraAttackThisAction (dread ambusher, etc AND we have action surge), apply it twice
        if (validTurnActions.length > 0) {
          const lastTurnAttack = validTurnActions[validTurnActions.length - 1]
          lastTurnAttack.bonusDamageDiceCollection.addDice(feature.extraAttackFirstRoundDice)
        }
      }

      if (feature.forgoAdvantageNextAttack) {
        const firstTurnAttack = turnActions[0]
        firstTurnAttack.forgoAdvantage = true // for future features that may try to turn on advantage

        if (firstTurnAttack.advantage > 0) {
          firstTurnAttack.advantage = 0
        }
      }

      if (feature.additionalDamageDiceOnCrit > 0) {
        addFeature((turn) => (turn.additionalCritDice += feature.additionalDamageDiceOnCrit))
      }

      if (feature.addWeaponStatModifier) {
        addFeature(function addWeaponStatModifier(turn: TurnAction): void {
          const abilityIndex = turn.attackAttributes().attackStatIndex

          const score = scores[abilityIndex]
          const statModifier = Utility.modifierForScore(score)
          if (abilityIndex !== undefined) {
            turn.bonusDamageDiceCollection.modifier += statModifier
          } else {
            console.error('No attack stat index found for weapon')
          }
        })
      }

      if (feature.additionalDamageDiceOnHit > 0) {
        addFeature((turn) => (turn.additionalDamageDice += feature.additionalDamageDiceOnHit))
      }

      if (feature.rerollDamageDiceThreshold > 0) {
        addFeature((turn) => (turn.rerollDamageDiceThreshold = feature.rerollDamageDiceThreshold))
      }

      if (feature.minimumDamageDieRoll > 0) {
        addFeature((turn) => (turn.minimumDieRoll = feature.minimumDamageDieRoll))
      }

      if (feature.rerollToHit) {
        addFeature((turn) => (turn.rerollToHit = feature.rerollToHit))
      }

      if (feature.rerollAllDamageDiceOnHit) {
        addFeature((turn) => (turn.rerollAllDamageDiceOnHit = feature.rerollAllDamageDiceOnHit))
      }

      if (feature.maxDamage) {
        addFeature((turn) => (turn.maxDamage = feature.maxDamage))
      }

      if (feature.disadvantage) {
        addFeature((turn) => (turn.disadvantage = feature.disadvantage))
      }

      if (feature.damageMultiplier > 1) {
        addFeature((turn) => (turn.damageMultiplier = feature.damageMultiplier))
      }

      if (feature.convertActionToBonusAction) {
        addFeature((turn) => (turn.activation = Activation.BonusAction()))
      }

      if (feature.minimumD20Roll) {
        addFeature((turn) => (turn.minimumD20Roll = feature.minimumD20Roll))
      }

      if (feature.additionalEffectCount > 0) {
        addFeature(function addEffectCount(turn: TurnAction): void {
          const attrs = turn.attributes()
          attrs.effectCount += feature.additionalEffectCount
        })
      }

      //   if (feature.additionalMeleeRange > 0) {
      //     addFeature(function addMeleeRange(turn: TurnAction): void {
      //       // TODO - this mutates the original attributes, which stacks. It also applies twic?
      //       //   const attrs = turn.attributes()
      //       //   attrs.range.range = attrs.range.range + feature.additionalMeleeRange
      //       //   attrs.range.longRange = attrs.range.longRange + feature.additionalMeleeRange
      //     })
      //   }

      if (feature.advantage) {
        addFeature(function setAdvantage(turn: TurnAction): void {
          if (turn instanceof Spell) {
            // TODO - is this ever possible?
            return
          }

          if (!turn.forgoAdvantage) {
            let advantage = elvenAccuracy ? 2 : 1
            if (turn.isCompanion()) {
              advantage = 1
            }

            turn.advantage = advantage
          }
        })
      }

      if (feature.expandedCrit) {
        addFeature((turn) => (turn.critThreshold -= 1))
      }

      if (feature.replacementDamageDice) {
        addFeature((turn) => (turn.replacementDamageDice = feature.replacementDamageDice!.copy()))
      }

      if (feature.autoCrit) {
        addFeature((turn) => (turn.autoCrit = feature.autoCrit))
      }

      if (feature.potentCantrip) {
        addFeature((turn) => (turn.missForHalfDamage = feature.potentCantrip))
        addFeature((turn) => (turn.saveForHalfDamage = feature.potentCantrip))
      }

      if (feature.spellSaveDCIncrease > 0) {
        addFeature((turn) => (turn.spellSaveDCIncrease = feature.spellSaveDCIncrease))
      }
    }
  }

  classNamesForDisplay(): string {
    const sortedClassNames = this.classNames.sort((a, b) => b[1] - a[1])
    return sortedClassNames.map((className: [string, number]) => `${className[0]} ${className[1]}`).join(' / ')
  }

  featNamesForDisplay(): string {
    const sortedFeatNames = this.featNames.sort((a, b) => a.localeCompare(b))
    return sortedFeatNames.join(', ')
  }

  testDescriptorForDisplay(): string {
    return this.classNamesForDisplay() + ' – ' + this.featNamesForDisplay()
  }
}
