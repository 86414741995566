import React, { useState, useEffect } from 'react'
import { Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton, Box } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { DISCORD_URL } from '../Common/Constants'
import { Link } from '@chakra-ui/react'

export function WeeklyAlert() {
  // State to track if the alert should be shown
  const [showAlert, setShowAlert] = useState(false)
  const accentColor = 'blue.200'
  const alertName = 'PHB2024AlertDisplayed'
  useEffect(() => {
    const alertDisplayed = Cookies.get(alertName)

    // If the cookie does not exist, show the alert
    if (!alertDisplayed) {
      setShowAlert(true)
    }
  }, [])

  const handleClose = () => {
    // Hide the alert
    setShowAlert(false)

    // Set the cookie 'alertDisplayed' with an expiration of 7 days
    Cookies.set(alertName, 'true', { expires: 7 })
  }

  return showAlert ? (
    <Alert status="info">
      <AlertIcon />
      <AlertTitle>2024 Player's Handbook</AlertTitle>
      <AlertDescription>
        is not yet fully supported. Visit our{' '}
        <Link color={accentColor} target="_blank" href={DISCORD_URL}>
          Discord
        </Link>{' '}
        for progress and updates.
      </AlertDescription>
      <CloseButton position="absolute" right="8px" top="8px" onClick={handleClose} />
    </Alert>
  ) : (
    <Box />
  )
}
