import { Dictionary } from '../Common/Types'

export enum PrivacyType {
  Private = 1,
  CampaignOnly = 2,
  Public = 3
}

class CampaignCharacter {
  characterId: number
  characterName: string
  characterUrl: string
  avatarUrl: string
  privacyType: number
  isAssigned: boolean

  constructor(data: Dictionary) {
    this.characterId = data.characterId
    this.characterName = data.characterName
    this.characterUrl = data.characterUrl
    this.avatarUrl = data.avatarUrl
    this.privacyType = data.privacyType
    this.isAssigned = data.isAssigned
  }

  isPublic(): boolean {
    return this.privacyType === PrivacyType.Public
  }
}

export class Campaign {
  characters: CampaignCharacter[] = []

  constructor(data: Dictionary) {
    if (data?.characters) {
      this.characters = data.characters.map((c: Dictionary) => new CampaignCharacter(c))
    }
  }
}
