import { Character } from './Character'
import { CharacterInfo } from './CharacterInfo'
import { Dictionary } from '../Common/Types'
import { Loader } from '../Loader/Loader'

export class DDBLoader {
  static load(characterData: Dictionary, callback: (data: any) => void): void {
    const subclassesAndLevels = characterData.data.classes
      .map((cc: any) => [cc.subclassDefinition?.id ?? 0, cc.level])
      .filter((id: number[]) => id[0] !== 0)

    async function loadAlwaysPreparedSpells(subclassId: number, level: number): Promise<any> {
      return new Promise((resolve, reject) => {
        Loader.sharedLoader().fetchAlwaysPreparedSpells(subclassId, level, false, (data) => {
          if (data) {
            resolve(data)
          } else {
            reject(new Error('Failed to fetch always prepared spells'))
          }
        })
      })
    }

    const requests = subclassesAndLevels.map((tuple: number[]) => {
      const subclassId = tuple[0]
      const level = tuple[1]
      return loadAlwaysPreparedSpells(subclassId, level)
    })

    Promise.all(requests).then((responses) => {
      const character = new Character(characterData.data, responses)
      const info = new CharacterInfo(character)
      callback(info)
    })
  }
}
