import { AttackAction } from '../DDB/AttackAction'
import { Activation } from '../DDB/Activation'
import { Dice } from './Dice'
import { Range } from '../DDB/Range'
import { COMPANION } from '../Common/Constants'
import { Dictionary } from '../Common/Types'
import { Character } from './Character'

export enum CreatureType {
  WildShape = 1,
  Familiar = 2,
  BeastCompanion = 3,
  Mount = 4,
  Pet = 5,
  Summon = 6,
  Misc = 7,
  Unknown1 = 8,
  Unknown2 = 9,
  SteelDefender = 10,
  Sidekick = 11,
  Infusion = 12
}

// const creatureTypeNames = {
//   1: 'Wild Shape',
//   2: 'Familiars',
//   3: 'Beast Companions',
//   4: 'Mounts',
//   5: 'Pets',
//   6: 'Summons',
//   7: 'Misc',
//   8: 'Unknown Type',
//   9: 'Unknown Type',
//   10: 'Steel Defenders',
//   11: 'Sidekicks',
//   12: 'Infusions'
// }

export class Creature {
  id: number // id
  name: string // name
  race: string // definition.name
  avatarURL: string
  largeAvatarURL: string
  creatureType: CreatureType
  attacks: AttackAction[] = []
  owner: Character

  constructor(data: Dictionary, character: Character) {
    const { definition } = data
    this.id = data.id
    this.name = data.name
    this.creatureType = parseInt(data.groupId)
    this.owner = character
    this.race = definition.name
    if (!this.name) {
      this.name = this.race
    }

    this.avatarURL = definition.avatarURL
    this.largeAvatarURL = definition.largeAvatarURL
    const actionsDescription = definition.actionsDescription

    const attackChunks: string[] = actionsDescription.split('<p>')
    for (const chunk of attackChunks) {
      const creatureAttack = this.lookForMatches(chunk)

      if (creatureAttack) {
        const { name, toHit, range, longRange, diceCount, diceValue, fixedValue } = creatureAttack
        const dice = Dice.Create(diceCount, diceValue, fixedValue)
        const attributes: Dictionary = {
          range: Range.makeWeaponRange(range, longRange),
          type: COMPANION,
          creatureType: this.creatureType
        }
        const activation = this.creatureType === CreatureType.WildShape ? Activation.Action() : Activation.BonusAction()

        const attackAction = new AttackAction(
          this.id + data.definition.id + this.attacks.length,
          `${this.name}: ${name}`,
          toHit,
          dice,
          attributes,
          activation
        )

        this.attacks.push(attackAction)
      }
    }
  }

  lookForMatches(chunk: string): CreatureAttack | null {
    const text = chunk.replace(/<[^>]+>/g, '')
    if (text.length === 0) {
      return null
    }

    // const rangeOrReachRegex = /reach|range (\d+\/\d+|\d+) ft./

    // Bite. Melee Weapon Attack: +4 to hit, reach 5 ft., one creature. Hit: 5 (1d6 + 2) piercing damage.
    let regex = /(\b.+?)\.[^+]+(\+\d+) to hit, \w+ (\d+\/\d+|\d+) ft.+?(\d+)+d(\d+) \+ (\d+)\) (\w+) damage/

    let matches = text.match(regex)
    if (matches) {
      return this.matchToCreatureAttack(matches, [1, 2, 3, 4, 5, 6])
    }

    // Bite. Melee Weapon Attack: +4 to hit, reach 0 ft., one creature in the swarm's space. Hit: 5 (2d4) piercing damage, or 2 (1d4) piercing damage if the swarm has half of its hit points or fewer
    regex = /(\b.+?)\.[^+]+(\+\d+) to hit, \w+ (\d+\/\d+|\d+) ft.+?(\d+)+d(\d+)\) (\w+) damage/ // (2d4), no +2
    matches = text.match(regex)
    if (matches) {
      return this.matchToCreatureAttack(matches, [1, 2, 3, 4, 5])
    }

    // Bite. Melee Weapon Attack: +4 to hit, reach 5 ft., one creature. Hit: 1 piercing damage
    regex = /(\b.+?)\.[^+]+(\+\d+) to hit, \w+ (\d+\/\d+|\d+) ft.+? (\d+) (\w+) damage/ // (2d4), no +2
    matches = text.match(regex)
    if (matches) {
      return {
        name: matches[1],
        toHit: Number(matches[2]),
        range: Number(matches[3]),
        diceCount: 0,
        diceValue: 0,
        fixedValue: Number(matches[4])
      }
    }

    if (!text.includes('Multiattack')) {
      console.warn(`Could not match ${this.name}'s attack [${text}] for ${this.owner.name}`)
    }

    return null
  }

  matchToCreatureAttack(arr: RegExpMatchArray, indices: number[]): CreatureAttack {
    const rawRange = arr[indices[2]]
    let range: number = Number(rawRange)
    let longRange = 0
    const matchedRange: string[] = String(rawRange).split('/')
    if (matchedRange.length === 2) {
      range = Number(matchedRange[0])
      longRange = Number(matchedRange[1])
    }

    return {
      name: arr[indices[0]],
      toHit: Number(arr[indices[1]]),
      range: Number(range),
      longRange: Number(longRange),
      diceCount: Number(arr[indices[3]]),
      diceValue: Number(arr[indices[4]]),
      fixedValue: Number(arr[indices[5]]) || 0
    }
  }
}

interface CreatureAttack {
  name: string
  toHit: number
  range: number
  longRange?: number
  diceCount: number
  diceValue: number
  fixedValue: number
}
