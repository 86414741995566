import { Dice } from './Dice'
import { Activation } from '../DDB/Activation'
import { AttackActionInterface } from '../Common/Interfaces'
import { Dictionary } from '../Common/Types'

export class AttackAction implements AttackActionInterface {
  id: number
  name: string
  attackMod: number
  activation: Activation
  attributes: Dictionary
  dice: Dice
  turnLevel: number = 0

  constructor(
    id: number,
    name: string,
    attackMod: number,
    dice: Dice,
    attributes: Dictionary = {},
    activation: Activation
  ) {
    this.id = id
    this.name = name
    this.attackMod = attackMod
    this.dice = dice.copy()
    this.activation = activation

    this.attributes = attributes

    if (!this.attributes.id) {
      this.attributes.id = this.id
    }

    // Manually created attacks (summons, etc) - default to true without having to keep passing them in
    if (this.attributes.requiresAttackRoll === undefined) {
      this.attributes.requiresAttackRoll = true
    }
  }

  diceString(): string {
    return this.dice.diceString()
  }

  diceStringForLevel(level: number): string {
    if (
      level > 0 &&
      this.attributes.diceCollectionsForLevels &&
      this.attributes.diceCollectionsForLevels.length > 0 &&
      this.attributes.diceCollectionsForLevels.length >= level - 1
    ) {
      return this.attributes.diceCollectionsForLevels[level - 1].displayString()
    }

    if (this.attributes.bonusDamageDice) {
      const diceCollection = this.attributes.bonusDamageDice.copy()
      return diceCollection.addDice(this.dice).displayString()
    }

    return this.dice.diceString()
  }

  copy(): AttackActionInterface {
    const copiedAttributes = {}
    Object.assign(copiedAttributes, this.attributes)
    const dice = this.dice.copy()
    return new AttackAction(this.id, this.name, this.attackMod, dice, copiedAttributes, this.activation)
  }

  attackModString(increase: number = 0): string {
    if (this.attributes.requiresAttackRoll) {
      if (this.attackMod > 0) {
        return '+' + this.attackMod.toString()
      }
      if (this.attackMod < 0) {
        return this.attackMod.toString()
      }

      return '+0'
    }

    if (this.attributes.requiresSavingThrow) {
      return this.saveDCString(increase)
    }

    return ''
  }

  saveDCString(increase: number = 0): string {
    const ability = this.attributes.saveDcAbility
    const value = this.attributes.saveDcValue + increase

    if (ability && value) {
      return ability + ' ' + value
    }
    return ''
  }

  attackString(): string {
    const diceString = this.diceString()
    if (this.attackMod > 0) return '+' + this.attackMod.toString() + ' ' + diceString
    if (this.attackMod < 0) return this.attackMod.toString() + ' ' + diceString
    return diceString
  }
}
