import React from 'react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { CharacterPage } from './CharacterPage'
import { Campaign } from '../DDB/Campaign'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'
import { TabIndicator } from '@chakra-ui/react'
import { DprTab, MainNavBar } from './MainNavBar'

export function CharacterPageTabs() {
  const { id } = useParams<{ id: string }>()
  const cid = parseInt(id ?? '0')
  const [campaign, setCampaign] = useState<Campaign>(new Campaign({}))
  const [characterName, setCharacterName] = useState<string>('Loading…')

  const otherCharacters = campaign.characters
    ? campaign.characters.filter((c) => c.characterId !== cid && c.isPublic())
    : []

  const multipleCharacters = otherCharacters.length > 0

  const showParty = true

  let element = undefined
  if (!showParty || !multipleCharacters) {
    element = <CharacterPage id={cid} isPrimary={true} setCampaign={setCampaign} setCharacterName={setCharacterName} />
  } else {
    element = (
      <Box borderBottomWidth="1px" width="100%">
        <Tabs variant="underline">
          <TabList overflowX="auto" overflowY="hidden" px={4}>
            <Tab fontSize={'md'}>{characterName}</Tab>
            {otherCharacters.map((character) => (
              <Tab key={character.characterId} fontSize={'md'}>
                {character.characterName}
              </Tab>
            ))}
          </TabList>
          <TabIndicator />
          <TabPanels>
            <TabPanel key={id} px={0} py={0}>
              <CharacterPage id={cid} isPrimary={true} setCampaign={setCampaign} setCharacterName={setCharacterName} />
            </TabPanel>
            {otherCharacters.map((character) => (
              <TabPanel key={character.characterId} px={0} py={0}>
                <CharacterPage
                  id={character.characterId}
                  isPrimary={false}
                  setCampaign={setCampaign}
                  setCharacterName={setCharacterName}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    )
  }

  return <MainNavBar tab={DprTab.CHARACTER} body={element} />
}
